import 'core-js/stable';
import 'regenerator-runtime/runtime';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import App from './App.vue';
import Api from './api';
import router from './router.js';
import utils from './utils.js';
import store from './store';
import { i18n, routeLanguageDetection } from './i18n.js';
import notify from './notify.js';
import loader from './loader.js';
import bus from './bus.js';
import './analytics.js';
import { apolloClient, apolloProvider } from '@/apollo';
import { provideApolloClient } from '@vue/apollo-composable';
import { Auth } from '@aws-amplify/auth';
import { createApp } from 'vue';
import './validate.js';

console.info('Loading MyCodabox...');

if (window.location.href.indexOf('/verify') >= 0 ||
    window.location.href.indexOf('/refuse-verification') >= 0) {
    utils.nukeAuthentications();
}

const time = Date.now();

//  Init Vue app
const app = createApp(App);

async function initializeAndMountApp () {
    try {
        await store.dispatch('init');
        // Init Cognito
        Auth.configure({
            region: store.state.env.cognitoRegion,
            userPoolId: store.state.env.cognitoUserPoolId,
            userPoolWebClientId: store.state.env.cognitoClientId,
            mandatorySignIn: true,
        });

        routeLanguageDetection(router);

        app.use(router);
        app.use(store);
        app.use(i18n);
        app.use(apolloProvider);
        provideApolloClient(apolloClient);

        if (['production', 'sandbox', 'staging', 'svaerschot'].includes(store.state.env.appEnv)) {
            Sentry.init({
                app,
                dsn: 'https://e2b11324902f4c6e8c1416a3858d4bfa@o48503.ingest.sentry.io/103887',
                integrations: [new Integrations.BrowserTracing()],
                traceSampleRate: 1.0,
                logErrors: true,
                environment: store.state.env.appEnv,
            });
        }

        app.mount('#app');
        console.info('... Loaded in ' + (Date.now() - time) + 'msec');
    } catch (error) {
        console.error('Error during store initialization:', error);
    }
}

initializeAndMountApp();

window.utils = utils;
window.Api = Api;
window.store = store;
window.router = router;
window.notify = notify;
window.loader = loader;
window.bus = bus;

<script>
import mitt from 'mitt';
import { gql } from '@apollo/client/core';
import _ from 'lodash';
import DeactivateVoila from './components/DeactivateVoila';
import ResendVoilaInviteButton from './components/ResendVoilaInviteButton';
import OrderVoila from './components/OrderVoila';
import SupplierList from './components/SupplierList';
import Loader from '@/loader';
import { VoilaMandateStates, VoilaMandateReasonCodes } from '@/constants/voila2';
import notify from '@/notify.js';
import FormInput from '@/components/FormInput';
import VoilaStatePill from '@/fiduciary/VoilaStatePill';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import ContentBox from '@/components/ContentBox.vue';
import EmailPill from '@/components/EmailPill.vue';
import Translated from '@/components/Translated';
import VoilaPeppolPill from '@/fiduciary/VoilaPeppolPill';
import { simpleLocale, t } from '@/i18n';
import { Form, Field } from 'vee-validate';
import FormToggle from '@/components/FormToggle';
import InfoBanner from '@/components/InfoBanner';
import InfoBannerItem from '@/components/InfoBannerItem';
import Icon from '@/components/Icon';
import ConfirmationPopup from '@/fiduciary/components/ConfirmationPopup';

export default {
    name: 'ClientVoila',
    props: {
        clientV2: {
            type: Object,
            required: true,
        },
        accountant: {
            type: Object,
            required: true,
        },
        currentEnvironment: {
            type: Object,
            required: true,
        },
    },
    components: {
        DeactivateVoila,
        ResendVoilaInviteButton,
        OrderVoila,
        SupplierList,
        FormInput,
        VoilaStatePill,
        FriendlyButton,
        ContentBox,
        EmailPill,
        Translated,
        VoilaPeppolPill,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
        Field,
        FormToggle,
        InfoBanner,
        InfoBannerItem,
        Icon,
        ConfirmationPopup,
    },
    data () {
        return {
            voila: {
                mandate: null,
                contactEmail: null,
            },
            edit: false,
            emailDeliveryMethod: null,
            onlyDeliverViaAPI: false,
            configCopy: {},
            localBus: mitt(),
            ibansForVoila: [],
            showVoilaNoEmailDeliveryConfirmationPopup: false,
        };
    },
    apollo: {
        voila: {
            query: gql`query VoilaMandate($clientId: String, $accountantId: String!) {
                    currentVoilaMandate(clientId:$clientId) {
                         id,
                         clientId,
                         emailDelivery,
                         deliveryEmail,
                         status,
                         reasonCode,
                         accessPointNames,
                         statusHistory {
                            code,
                            createdAt
                         },
                         ibans {
                            id,
                            value
                         }
                         pushDelivery,
                         signedVia
                    }
                    fiduciaryClient(clientId:$clientId) {
                        contactEmail,
                    }
                    invoiceSuppliers(clientId:$clientId) {
                        name
                        latestDocumentReceivedAt
                    }
                    thirdPartyIntegrators(clientId:$clientId) {
                        name
                    }
                    voilaBoostActivationByAccountant(accountantId:$accountantId) {
                        id
                    }
                }`,
            variables () {
                return {
                    clientId: this.clientV2.id,
                    accountantId: this.accountant.id,
                };
            },
            update: data => {
                return {
                    contactEmail: data.fiduciaryClient.contactEmail,
                    mandate: data.currentVoilaMandate,
                    suppliers: data.invoiceSuppliers,
                    thirdPartyIntegrators: data.thirdPartyIntegrators,
                    voilaBoostActivationByAccountant: data.voilaBoostActivationByAccountant,
                };
            },
            error (error, vm) {
                if (error.gqlError.extensions.code === 'FORBIDDEN') {
                    notify.error(t('err-restricted-access'));
                    this.$router.push({ name: 'index' });
                } else {
                    throw error;
                }
            },
        },
    },
    computed: {
        canResendInvitation () {
            return this.voila.mandate && (this.voila.mandate.status === VoilaMandateStates.InvitationExpired || this.voila.mandate.status === VoilaMandateStates.Invited);
        },
        voilaMandateCancellable () {
            return this.voila.mandate && [
                VoilaMandateStates.Invited,
                VoilaMandateStates.InvitationExpired,
                VoilaMandateStates.InValidation,
                VoilaMandateStates.InRegistration,
                VoilaMandateStates.Problem,
                VoilaMandateStates.Active,
            ].includes(this.voila.mandate.status);
        },
        canEdit () {
            return this.voila.mandate.status !== VoilaMandateStates.Cancelled;
        },
        voilaMandateOrderable () {
            return this.voila.contactEmail &&
                (!this.voila.mandate || [
                    VoilaMandateStates.Cancelled,
                ].includes(this.voila.mandate.status));
        },
        missingContactEmail () {
            return (!this.voila.mandate || this.voila.mandate.status === VoilaMandateStates.Cancelled) &&
                !this.voila.contactEmail;
        },
        hasReceivedInvoices () {
            return this.voila.suppliers ? this.voila.suppliers.length > 0 : false;
        },
        hasThirdPartyIntegrators () {
            return this.voila.thirdPartyIntegrators ? this.voila.thirdPartyIntegrators.length > 0 : false;
        },
        voilaMandateStatusColor () {
            if (this.voila.mandate) {
                if (!this.voila.mandate.status || this.voila.mandate.status === VoilaMandateStates.Cancelled) {
                    return '';
                } else if ([
                    VoilaMandateStates.InvitationExpired,
                    VoilaMandateStates.Problem,
                ].includes(this.voila.mandate.status)) {
                    return 'bg-red-400 bg-opacity-20';
                } else {
                    return '';
                }
            } else {
                return '';
            }
        },
        voilaMandateInitialAlreadyRegisteredOnPeppolErrorDate () {
            if (this.voila.mandate &&
                this.voila.mandate.status === VoilaMandateStates.Problem &&
                this.voila.mandate.reasonCode === VoilaMandateReasonCodes.AlreadyRegisteredOnPeppol) {
                const mandateStatusesNewestFirst = this.voila.mandate.statusHistory
                    .slice(0) // avoid side effect
                    .sort((status1, status2) => -status1.createdAt.localeCompare(status2.createdAt));
                let initialAlreadyRegisteredOnPeppolErrorDate;
                for (let i = 0; i < mandateStatusesNewestFirst.length; i++) {
                    if (mandateStatusesNewestFirst[i].code === VoilaMandateStates.Problem) {
                        initialAlreadyRegisteredOnPeppolErrorDate = mandateStatusesNewestFirst[i].createdAt;
                    } else if (mandateStatusesNewestFirst[i].code !== VoilaMandateStates.InRegistration) {
                        break;
                    }
                }
                const options = { year: 'numeric', month: 'long', day: 'numeric' };
                return new Date(initialAlreadyRegisteredOnPeppolErrorDate).toLocaleDateString(simpleLocale(this.$i18n.locale), options);
            } else return '';
        },
    },
    watch: {
        'voila.mandate': {
            handler (mandate) {
                if (mandate) {
                    this.emailDeliveryMethod = !mandate.emailDelivery ? 'noEmailDelivery' : mandate.deliveryEmail ? 'voilaEmail' : 'contactEmail';
                }
            },
            deep: true,
        },
        'voila.mandate.pushDelivery': {
            handler (pushDelivery) {
                this.onlyDeliverViaAPI = !pushDelivery;
            },
            deep: true,
        },
        '$apolloData.queries.voila.loading': {
            handler (loading) {
                if (loading) {
                    Loader.start();
                } else {
                    Loader.stop();
                }
            },
            deep: true,
        },
        clientV2: {
            handler () {
                this.localBus.emit('updated');
            },
            deep: true,
        },
        'voila.mandate.ibans': {
            handler () {
                if (this.voila.mandate) {
                    this.buildIbansForVoila();
                }
            },
            deep: true,
        },
    },
    beforeMount () {
        if (this.accountant.hasCodaOnlyContract || this.accountant.isDirectCustomer) {
            // user can't access this page for this environment
            notify.error(t('err-restricted-access'));
            this.$router.push({ name: 'index' });
        }
    },
    methods: {
        async fetchVoilaIbans () {
            const { data } = await this.$apollo.query({
                query: gql`query VoilaMandate($clientId: String) {
                    currentVoilaMandate(clientId:$clientId) {
                         ibans {
                            id,
                            value
                         }
                    }
                }`,
                variables: {
                    clientId: this.voila.mandate.clientId,
                },
            });

            this.voila.mandate.ibans = data.currentVoilaMandate.ibans;
        },
        editDeliveries () {
            this.edit = true;
            this.configCopy = {
                voilaMandate: _.clone(this.voila.mandate),
                emailDeliveryMethod: this.emailDeliveryMethod,
                onlyDeliverViaAPI: this.onlyDeliverViaAPI,
            };
        },
        cancelDeliveriesEdit () {
            this.voila.mandate = _.clone(this.configCopy.voilaMandate);
            this.emailDeliveryMethod = this.configCopy.emailDeliveryMethod;
            this.onlyDeliverViaAPI = this.configCopy.onlyDeliverViaAPI;
            this.edit = false;
        },
        async saveDeliveriesWithPossibleConfirmationPopup (values) {
            if (values.emailDeliveryMethod !== 'noEmailDelivery') {
                return this.saveDeliveries(values);
            } else {
                this.openVoilaNoEmailDeliveryConfirmationPopup();
            }
        },
        async saveDeliveries (values) {
            this.showVoilaNoEmailDeliveryConfirmationPopup = false;

            if (this.saving) { return; }

            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation SaveVoilaDeliveries($input: VoilaDeliveriesEditionInput!) {
                    saveVoilaDeliveries(input: $input) {
                        errors {
                            code,
                            detail,
                            source {
                                pointer
                            }
                        }
                    }
                }`,
                variables: {
                    input: {
                        clientId: this.voila.mandate.clientId,
                        id: this.voila.mandate.id,

                        deliveryEmail: values.emailDeliveryMethod === 'voilaEmail' ? values.deliveryEmail : null,
                        emailDelivery: values.emailDeliveryMethod !== 'noEmailDelivery',
                        pushDelivery: !values.onlyDeliverViaAPI,
                    },
                },
            });
            const response = data.saveVoilaDeliveries;

            if (response.errors) {
                const observerErrors = {};
                response.errors.forEach(error => {
                    const field = error.source.pointer.replace('/data/', '');
                    if (this.$refs.form.fields[field]) {
                        observerErrors[field] = this.$t(error.detail);
                    } else {
                        notify.error(this.$t(error.detail));
                    }
                });
                this.$refs.form.setErrors(observerErrors);
                return;
            }

            this.refreshVoilaMandates();
            notify.success(this.$t('suc-voila-deliveries-modified'));
            this.cancelDeliveriesEdit();
        },
        refreshVoilaMandates () {
            this.$apollo.queries.voila.refresh();
        },
        async addVoilaIban (iban) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation AddVoilaIban($input: VoilaIbanAdditionInput!) {
                        addVoilaIban(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.voila.mandate.clientId,
                            mandateId: this.voila.mandate.id,
                            ibanValue: iban,
                        },
                    },
                });
                await this.fetchVoilaIbans();
                notify.success(this.$t('p-voila-ibans-order-success'));
            } catch {
                notify.error(this.$t('p-voila-ibans-order-error'));
            }
        },
        async deleteVoilaIban (ibanId) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation DeleteVoilaIban($input: VoilaIbanDeletionInput!) {
                        deleteVoilaIban(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: this.voila.mandate.clientId,
                            mandateId: this.voila.mandate.id,
                            ibanId,
                        },
                    },
                });
                notify.success(this.$t('p-voila-ibans-delete-success'));
                await this.fetchVoilaIbans();
            } catch {
                notify.error(this.$t('p-voila-ibans-delete-error'));
            }
        },
        async buildIbansForVoila () {
            // Build list of IBANs that can be ordered for VOILA, based on CODA mandates
            // Contains also info if the IBAN is already ordered on VOILA
            Loader.start();
            const { data } = await this.$apollo.query({
                query: gql`query BankAccounts($clientId: String, $states: [String]) {
                    bankAccounts(clientId:$clientId, states:$states) {
                         iban
                    }
                }`,
                variables: {
                    clientId: this.voila.mandate.clientId,
                    states: ['active'],
                },
            });

            // Take only active bank accounts from Core that aren't already activated in VOILA (client can possibly activate it)
            this.ibansForVoila = data.bankAccounts.filter(ba => {
                const isOnVoila = this.voila.mandate.ibans.find(voilaIban => {
                    return voilaIban.value === ba.iban;
                });
                return !isOnVoila;
            });

            // Set "isVoila" to false to have same structure for BA in VOILA and BA not in VOILA
            this.ibansForVoila = this.ibansForVoila.map(ba => {
                return {
                    iban: ba.iban,
                    id: null,
                    isVoila: false,
                };
            });

            // Take the remaining bank accounts from Core that are already activated in VOILA,
            // add them to the IBANs, but with ID of VOILA and a isVoila to true
            this.voila.mandate.ibans.forEach(ba => {
                this.ibansForVoila.push({
                    iban: ba.value,
                    id: ba.id,
                    isVoila: true,
                });
            });
            this.ibansForVoila = _.orderBy(this.ibansForVoila, ['iban'], ['asc']);
            Loader.stop();
        },
        handleIbanForVoilaChange (bankAccount, shouldBeVoila) {
            Loader.start();
            if (shouldBeVoila) {
                this.addVoilaIban(bankAccount.iban);
            } else {
                this.deleteVoilaIban(bankAccount.id);
            }
        },
        openVoilaNoEmailDeliveryConfirmationPopup () {
            this.showVoilaNoEmailDeliveryConfirmationPopup = true;
        },
        closeVoilaNoEmailDeliveryConfirmationPopup () {
            this.showVoilaNoEmailDeliveryConfirmationPopup = false;
        },
    },
};
</script>

<template>
    <main v-if='!$apollo.loading'>
        <content-box
            class='mt-0 mb-6 overflow-hidden z-30'
        >
            <div class='z-10 flex items-center'>
                <div class='mr-6'>
                    <VoilaStatePill
                        :status='voila.mandate ? voila.mandate.status : null'
                        :reason-code='voila.mandate ? voila.mandate.reasonCode : null'
                        :initial-already-registered-on-peppol-error-date='voilaMandateInitialAlreadyRegisteredOnPeppolErrorDate'
                        :access-point-names='voila.mandate && voila.mandate.accessPointNames ? voila.mandate.accessPointNames.join(", ") : "unknown"'
                        inline
                    />
                </div>
                <div class='client-subppage-header__actions'>
                    <div v-if='voila.mandate && canEdit' class='flex'>
                        <ResendVoilaInviteButton
                            :mandate-id='voila.mandate.id'
                            :contact-email='voila.contactEmail'
                            v-if='canResendInvitation'
                            class='mr-3'
                            @done='refreshVoilaMandates'
                            extra-small
                            id='voila-reinvite-button'
                        />
                        <DeactivateVoila
                            :id='voila.mandate.id'
                            v-if='voilaMandateCancellable'
                            @done='refreshVoilaMandates'
                            extra-small
                        />
                    </div>
                    <div v-if='voilaMandateOrderable' class='flex'>
                        <OrderVoila
                            :id='clientV2.id'
                            @done='refreshVoilaMandates'
                            extra-small
                            :has-boost='!!voila.voilaBoostActivationByAccountant'
                            :accountant='accountant'
                        />
                    </div>
                </div>
            </div>
            <div
                class='inline-block border-0 border-t border-solid border-grey-100 w-full pt-6 mt-6'
                v-if='voila.mandate && voila.mandate.signedVia'
            >
                <span>
                    <i class='fa fa-info-circle mr-2'></i>
                    <span v-if='voila.mandate.signedVia === "boost"' id='boostPill'>{{ $t('lbl-voila-ordering-method-boost') }}</span>
                    <span v-else-if='voila.mandate.signedVia === "invitationEmail"' id='emailInvitationPill'>{{ $t('lbl-voila-ordering-method-email') }}</span>
                </span>
            </div>
        </content-box>
        <InfoBanner id='missing-contact-email-warning' class='mb-6'>
            <InfoBannerItem v-if='missingContactEmail'>
                {{ $t('info-voila-missing-contact-email') }}
            </InfoBannerItem>
        </InfoBanner>
        <Form
            ref='form'
            tag='div'
            @submit='saveDeliveriesWithPossibleConfirmationPopup'
            v-slot='{ values }'
            :initial-values='{ emailDeliveryMethod: emailDeliveryMethod }'
            v-if='emailDeliveryMethod'
        >
            <content-box :title='$t("ttl-settings")' v-if='voila.mandate'>
                <template #actions>
                    <div v-if='canEdit' class='flex'>
                        <template v-if='!edit'>
                            <FriendlyButton
                                id='voila_delivery_edit'
                                label='btn-edit'
                                :action='editDeliveries'
                                extra-small
                                no-margin
                                square
                            />
                        </template>
                        <template v-else>
                            <FriendlyButton
                                id='voila_delivery_cancel_edit'
                                label='btn-cancel'
                                :action='() => { cancelDeliveriesEdit(); }'
                                extra-small
                                no-margin
                                square
                                class='mr-3'
                            />
                            <FriendlyButton
                                id='voila_delivery_save'
                                label='btn-save'
                                type='submit'
                                extra-small
                                square
                                no-margin
                            />
                        </template>
                    </div>
                </template>
                <h4 class='mb-6 mt-0'>
                    {{ $t('ttl-voila-email-settings') }}
                </h4>
                <div class='mr-6' v-if='hasThirdPartyIntegrators'>
                    <div class='rounded-md bg-gray-100 text-grey-600 mb-4 px-6 py-3 flex gap-2'>
                        <Icon name='ExclamationTriangle' class='mr-1 w-5 text-yellow-400' />
                        {{ $t('ttl-voila-email-settings-info-third-party-delivery', {thirdPartyIntegrators : voila.thirdPartyIntegrators.map(integrator => integrator.name).join(', ')}) }}
                    </div>
                </div>
                <div class='flex items-center'>
                    <span class='flex items-center mt-3 mb-3'>
                        <Field
                            name='emailDeliveryMethod'
                            type='radio'
                            value='contactEmail'
                            :disabled='!edit'
                            id='emailDeliveryMethodContactEmail'
                            v-model='emailDeliveryMethod'
                        />
                        <label for='emailDeliveryMethodContactEmail' class='text-bold m-0 mx-3 mt-1'>{{ $t('ttl-voila-email-settings-toggle-contact') }}</label>
                    </span>
                    <span class='flex items-center'>
                        <span class='mr-3' v-if='voila.contactEmail'>
                            <i class='fa fa-arrow-right text-sm'></i>
                        </span>
                        <span class='font-bold truncate max-w-xs inline-block' id='voila_delivery_usecontactemail_input' v-if='edit'>{{ voila.contactEmail }}</span>
                        <EmailPill
                            :email='voila.contactEmail'
                            :client-id='clientV2.id'
                            inline
                            id='voila_delivery_usecontactemail_input'
                            v-if='!edit'
                            ref='contactEmailPill'
                            :bus='localBus'
                            :disable-actions='voila.mandate && voila.mandate.status === "cancelled"'
                        />
                    </span>
                </div>
                <div class='flex items-center'>
                    <span class='flex mt-3 mb-3'>
                        <Field
                            name='emailDeliveryMethod'
                            type='radio'
                            value='voilaEmail'
                            :disabled='!edit'
                            id='emailDeliveryMethodVoilaEmail'
                            v-model='emailDeliveryMethod'
                        />
                        <label for='emailDeliveryMethodVoilaEmail' class='text-bold m-0 mx-3 mt-1'>{{ $t('ttl-voila-email-settings-toggle-dedicated') }}</label>
                    </span>
                    <span v-if='(voila.mandate && voila.mandate.deliveryEmail) || values.emailDeliveryMethod === "voilaEmail"' class='flex items-center'>
                        <span class='mr-3'>
                            <i class='fa fa-arrow-right text-sm'></i>
                        </span>
                        <EmailPill
                            :email='voila.mandate.deliveryEmail'
                            :client-id='clientV2.id'
                            v-if='!edit'
                            inline
                            email-type='voila'
                            ref='voilaDeliveryEmailPill'
                            :bus='localBus'
                            :disable-actions='voila.mandate && voila.mandate.status === "cancelled"'
                        />
                        <FormInput
                            name='deliveryEmail'
                            :value='voila.mandate.deliveryEmail'
                            id='voila_delivery_usemandateemail_input'
                            :placeholder='$t("lbl-voila-delivery-email")'
                            :edit='edit && values.emailDeliveryMethod === "voilaEmail"'
                            :rules='`${values.emailDeliveryMethod === "contactEmail" ? "" : "required|"}email|max:254`'
                            class='inline-block font-bold'
                            no-margin
                            v-else
                        />
                    </span>
                </div>
                <ConfirmationPopup
                    vid='no-email-delivery-confirmation-popup'
                    :show='showVoilaNoEmailDeliveryConfirmationPopup'
                    @confirm='() => saveDeliveries(values)'
                    @close='closeVoilaNoEmailDeliveryConfirmationPopup'
                    :confirmation-title='$t("ttl-voila-no-email-delivery-confirmation")'
                    :confirmation-text='$t("lbl-voila-no-email-delivery-confirmation")'
                >
                    <p>
                        {{ $t('p-voila-no-email-delivery-confirmation-1') }}
                    </p>
                    <br>
                    <p>
                        <Translated>
                            <template #en>
                                Do not select this option unless <b>explicitly requested</b> by the company and if the company has <b>another channel</b> to receive and follow up on invoices.
                            </template>
                            <template #nl>
                                Selecteer deze optie enkel op <b>expliciete vraag</b> van de onderneming én als de onderneming een <b>ander kanaal</b> heeft om de facturen te ontvangen en op te volgen.
                            </template>
                            <template #fr>
                                Ne sélectionnez cette option qu'à la <b>demande explicite</b> de l'entreprise et si l'entreprise dispose d'un <b>autre canal</b> pour la réception et le suivi des factures.
                            </template>
                        </Translated>
                    </p>
                    <br>
                </ConfirmationPopup>
                <div class='flex items-center'>
                    <span class='flex items-center mt-3 mb-3'>
                        <Field
                            name='emailDeliveryMethod'
                            type='radio'
                            value='noEmailDelivery'
                            :disabled='!edit'
                            id='emailDeliveryMethodNoEmailDelivery'
                            v-model='emailDeliveryMethod'
                        />
                        <label for='emailDeliveryMethodNoEmailDelivery' class='text-bold m-0 mx-3 mt-1'>{{ $t('ttl-voila-email-settings-toggle-no-delivery') }}</label>
                    </span>
                </div>

                <h4 class='mb-6 mt-8'>
                    {{ $t('ttl-voila-2-delivery-settings') }}
                </h4>
                <div>
                    <FormToggle
                        id='voila_delivery_onlyapi'
                        :value='onlyDeliverViaAPI'
                        name='onlyDeliverViaAPI'
                        :edit='edit'
                        class='mb-3'
                        :info='$t("stngs-voila-2-client-block-push-delivery-info")'
                        :label='$t("stngs-voila-2-client-block-push-delivery")'
                    />
                </div>
            </content-box>
        </Form>
        <content-box :title='$t("h-voila-peppol")' class='mt-6' v-if='voila.mandate'>
            <template #info>
                <Translated>
                    <template #en>
                        Peppol stands for Pan European Public Procurement Online and is a network widely used for sending and receiving invoices.
                        By activating VOILA, by default the company is registered on Peppol by Codabox to receive invoices. More info on our <a target='_blank' href='https://faq.codabox.com/en/support/solutions/articles/75000057494-the-channels-peppol-and-zoomit'>FAQ</a>.
                    </template>
                    <template #nl>
                        Peppol staat voor Pan European Public Procurement Online en is een netwerk dat veel gebruikt wordt voor het verzenden en ontvangen van facturen.
                        Door VOILA te activeren, wordt het dossier standaard geregistreerd op Peppol door Codabox om facturen te ontvangen. Meer info op onze <a target='_blank' href='https://faq.codabox.com/nl/support/solutions/articles/75000057494-de-kanalen-peppol-en-zoomit'>FAQ</a>.
                    </template>
                    <template #fr>
                        Peppol (Pan European Public Procurement Online) est un réseau largement utilisé pour l'envoi et la réception de factures.
                        En activant VOILA, par défaut le dossier est enregistré sur Peppol par Codabox pour recevoir les factures. Plus d'informations sur notre <a target='_blank' href='https://faq.codabox.com/fr/support/solutions/articles/75000057494-les-canaux-peppol-et-zoomit'>FAQ</a>.
                    </template>
                </Translated>
            </template>
            <div class='mr-6'>
                <VoilaPeppolPill :status='voila.mandate.status' inline />
            </div>
        </content-box>
        <content-box :title='$t("ttl-voila-ibans")' class='mt-6' v-if='voila.mandate'>
            <template #info>
                <Translated>
                    <template #en>
                        Zoomit is a service which allows senders to deliver invoices in the banking app of the company.
                        By activating a bank account, invoices available on this bank account on Zoomit will be directly retrieved by Codabox.
                        Please note: only bank accounts for which we have an active CODA-mandate can be activated on Zoomit through VOILA.
                        More info on our <a target='_blank' href='https://faq.codabox.com/en/support/solutions/articles/75000057494-the-channels-peppol-and-zoomit'>FAQ</a>.
                    </template>
                    <template #nl>
                        Zoomit is een dienst waarmee verzenders facturen kunnen aanleveren in de bank-app van het dossier.
                        Door een bankrekening te activeren worden de facturen die beschikbaar zijn op deze bankrekening, rechtstreeks opgehaald op Zoomit door Codabox.
                        Opgelet: enkel bankrekeningen waarvoor wij een actief CODA-mandaat hebben, kunnen geactiveerd worden op Zoomit door VOILA.
                        Meer info op onze <a target='_blank' href='https://faq.codabox.com/nl/support/solutions/articles/75000057494-de-kanalen-peppol-en-zoomit'>FAQ</a>.
                    </template>
                    <template #fr>
                        Zoomit est un service qui permet aux expéditeurs de délivrer des factures dans l'application bancaire du dossier.
                        En activant un compte bancaire, les factures disponibles sur ce compte via Zoomit seront directement récupérées par Codabox.
                        Attention: seuls les comptes bancaires pour lesquels nous avons un mandat CODA actif peuvent être activés sur Zoomit via VOILA.
                        Plus d'infos dans notre <a target='_blank' href='https://faq.codabox.com/fr/support/solutions/articles/75000057494-les-canaux-peppol-et-zoomit'>FAQ</a>.
                    </template>
                </Translated>
            </template>
            <div v-if='ibansForVoila.length'>
                <p class='mb-6'>
                    {{ $t('p-voila-ibans-instructions') }}
                </p>
                <div v-for='(bankAccount, index) in ibansForVoila' :key='index'>
                    <FormToggle
                        :name='`ibanForVoila[${index}]`'
                        :id='`iban-for-voila-${index}`'
                        edit
                        :label='bankAccount.iban'
                        :value='bankAccount.isVoila'
                        :disabled='voila.mandate && voila.mandate.status === "cancelled"'
                        @change='shouldBeVoila => { handleIbanForVoilaChange(bankAccount, shouldBeVoila) }'
                        class='mb-3'
                    />
                </div>
            </div>
            <div v-else>
                <p class='mb-6'>
                    {{ $t('p-voila-ibans-no-ibans') }}
                </p>
            </div>
        </content-box>
        <content-box :title='$t("h-voila-suppliers")' class='mt-6' v-if='voila.mandate'>
            <div v-if='hasReceivedInvoices'>
                <SupplierList :suppliers='voila.suppliers' />
            </div>
            <div v-else>
                <p>{{ $t('p-no-invoice-received-yet') }}</p>
            </div>
        </content-box>
    </main>
</template>

<style scoped lang='postcss'>
    #voila_delivery_usemandateemail_input {
        min-width: 225px;
    }
</style>

<script>
    import Selector from './Selector.vue';
    import Loader from '../loader.js';
    import { authFetch as fetch } from '../api/fetch.js';
    import download from 'downloadjs';
    import notify from '../notify.js';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Dropdown from '@/components/DropdownV2';
    import DropdownGroup from '@/components/DropdownGroup';

    export default {
        name: 'ExporterButton',
        props: {
            model: {
                type: String,
                required: true,
            },
            fiduciaryId: {
                type: String,
                required: true,
            },
            label: {
                type: String,
                default: () => 'Export',
            },
        },
        components: {
            Selector,
            FriendlyButton,
            Dropdown,
            DropdownGroup,
        },
        data () {
            return {
                open: false,
                format: {
                    selected: this.getSavedFormat(),
                    options: [
                        { value: 'csv', label: 'CSV' },
                        { value: 'xls', label: 'Excel (.xls)' },
                        { value: 'xlsx', label: 'Excel (.xlsx)' },
                    ],
                },
                dropdownOpened: false,
            };
        },
        methods: {
            getSavedFormat () {
                if (localStorage['cb-export-format'] in { csv: '', xls: '', xlsx: '' }) {
                    return localStorage['cb-export-format'];
                } else {
                    return 'csv';
                }
            },
            async downloadFile (model, format, fiduciaryId) {
                const mimeTypes = {
                    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    csv: 'text/csv',
                    xls: 'application/vnd.ms-excel',
                };

                const url = {
                    companies: `bff/api/exports/clients?fiduciaryId=${fiduciaryId}`,
                    'bank-accounts': `bff/api/exports/bank-accounts?fiduciaryId=${fiduciaryId}`,
                    'coda-mandates': `bff/api/exports/coda-mandates?fiduciaryId=${fiduciaryId}`,
                    'soda-mandates': `bff/api/exports/soda-mandates?fiduciaryId=${fiduciaryId}`,
                }[model];

                const res = await fetch(
                    url,
                    {
                        method: 'GET',
                        headers: {
                            Accept: mimeTypes[format] || 'text/csv',
                            'Content-Type': '',
                        },
                    },
                );
                if (res.status !== 200) {
                    throw Error(res.statusText);
                }
                const blob = await res.blob();

                return download(blob, `${model}.${format}`);
            },
            async doExport () {
                localStorage['cb-export-format'] = this.format.selected;
                const loadId = Loader.start();
                try {
                    await this.downloadFile(
                            this.model,
                            this.format.selected,
                            this.fiduciaryId,
                        );
                } catch {
                    notify.error(this.$t('err-export-failed'));
                }
                Loader.stop(loadId);
            },
        },
    };
</script>
<template>
    <Dropdown
        class='z-50 text-left'
        direction='right'
        :name='label'
        menu-extra-classes='overflow-y-visible'
        secondary
        button-small
    >
        <DropdownGroup>
            <div class='mb-3'>
                <label class='text-md font-bold'>{{ $t('lbl-file-format') }}</label>
                <Selector :model='format' />
            </div>
            <FriendlyButton
                label='btn-download'
                :action='doExport'
                symbol='download'
                square
                extra-small
                no-margin
            />
        </DropdownGroup>
    </Dropdown>
</template>

<script>
    import { gql } from '@apollo/client/core';
    import PrismicPopup from '../components/PrismicPopup.vue';
    import notify from '@/notify.js';
    import NavBar from '@/components/NavBar.vue';
    import Sidenav from '@/components/sidenav/Sidenav.vue';
    import SidenavItem from '@/components/sidenav/SidenavItem.vue';
    import SidenavGroup from '@/components/sidenav/SidenavGroup.vue';

    export default {
        name: 'ResellerRoot',
        components: {
            PrismicPopup,
            NavBar,
            Sidenav,
            SidenavItem,
            SidenavGroup,
        },
        data () {
            return {
                loaded: false,
                reseller: null,
            };
        },
        watch: {
            '$route.params.resellerId': async function (newValue) {
                await this.loadResellerData(newValue);
            },
        },
        async beforeRouteUpdate (to, from, next) {
            if (
                to.name === 'reseller' &&
                to.params.resellerId === from.params.resellerId
            ) {
                await this.loadResellerData(to.params.resellerId);
            } else {
                next();
            }
        },
        async mounted () {
            await this.loadResellerData(this.$route.params.resellerId);
        },
        methods: {
            async loadResellerData (resellerId) {
                try {
                    const { data } = await this.$apollo.query({
                        query: gql`query resellerData($resellerId: String!) {
                            reseller(resellerId:$resellerId) {
                                id
                                name
                                state
                                language
                                fiduciaries {
                                    id
                                    name
                                    accountantId
                                }
                                organizations {
                                    id
                                    enterpriseName
                                }
                            }
                        }`,
                        variables: { resellerId },
                    });
                    this.reseller = data.reseller;
                    this.loaded = true;
                } catch {
                    notify.error(this.$t('ttl-err-no-access'));
                    this.$router.push('/');
                }
            },
        },
    };
</script>

<template>
    <div class='h-full'>
        <NavBar
            :user='$store.state.user'
            :reseller='reseller'
            :help-url='$t("help-url")'
        />
        <PrismicPopup space='reseller' />

        <div class='h-full lg:flex'>
            <Sidenav v-if='loaded' collapsable>
                <SidenavGroup :title='$t("nav-environments")' collapsable background>
                    <SidenavItem
                        :to='{ name: "reseller-environments-search", params: { resellerId: reseller.id } }'
                        :text='$t("nav-environments-list")'
                        :active-route='"reseller-environments-search"'
                    />
                </SidenavGroup>

                <SidenavGroup :title='$t("nav-documents")' collapsable background>
                    <SidenavItem
                        :to='{ name: "reseller-coda-search" }'
                        :text='$t("nav-fidu-coda-search")'
                        :active-route='"reseller-coda-search"'
                    />
                    <SidenavItem
                        :to='{ name: "reseller-soda-search" }'
                        :text='$t("nav-fidu-soda-search")'
                        :active-route='"reseller-soda-search"'
                    />
                </SidenavGroup>
            </Sidenav>

            <div v-if='loaded' class='pl-6 lg:pl-0 pr-6 lg:h-full w-full lg:overflow-y-scroll'>
                <router-view :current-reseller='reseller' />
            </div>
        </div>
    </div>
</template>

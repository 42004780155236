<template>
    <div class='h-full'>
        <NavBar :user='$store.state.user' :help-url='$t("help-url")' />
        <div class='w-full pt-12 pb-24 flex items-center h-full'>
            <div
                v-if='validSession && noOrganizationAccess && noResellerAccess'
                class='container flex flex-col items-center justify-center mb-12'
            >
                <CustomTitle class='mb-6 text-center'>
                    {{ $t('add-new-organization.title-part-1') }}
                    <br>
                    {{ $t('add-new-organization.title-part-2') }}
                </CustomTitle>
                <FriendlyButton
                    label='add-new-organization.btn-open-popup'
                    :action='showAddNewOrganizationPopup'
                    square
                    small
                    no-margin
                    id='add-new-organization-button'
                />

                <!-- eslint-disable-next-line vue/no-v-html -->
                <p class='text-center text-xl max-w-4xl mx-auto mt-8 italic text-grey-600' v-html='$t("add-new-organization.p-already-exists")'></p>

                <Tooltip class='mt-8'>
                    <template #trigger>
                        <div class='flex gap-2'>
                            <Icon name='InformationCircle' class='text-grey-500' family='outline' />
                            <p class='text-lg text-grey-500'>
                                {{ $t('add-new-organization.what-is-an-org.label') }}
                            </p>
                        </div>
                    </template>
                    <template #content>
                        {{ $t('add-new-organization.what-is-an-org.text') }}
                    </template>
                </Tooltip>
            </div>
        </div>
        <OnboardingHelper />
    </div>
</template>
<script>
import CustomTitle from '@/components/Title';
import NavBar from '../components/NavBar.vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import Icon from '@/components/Icon.vue';
import OnboardingHelper from '@/user/OnboardingHelper';

export default {
    name: 'HomeView',
    components: {
        FriendlyButton,
        CustomTitle,
        NavBar,
        Tooltip,
        Icon,
        OnboardingHelper,
    },
    data: function () {
        return {
            validSession: false,
        };
    },
    computed: {
        noOrganizationAccess () {
            return this.$store.state.user.organizationIds.length === 0;
        },
        noResellerAccess () {
            return this.$store.state.user.resellerIds.length === 0;
        },
    },
    emits: ['openNewOrganizationPopup'],
    async mounted () {
        try {
            await this.$store.dispatch('checkSession');
            this.validSession = true;
        } catch (e) {
            this.validSession = false;
        }
    },
    methods: {
        showAddNewOrganizationPopup () {
            this.$emit('openNewOrganizationPopup');
        },
    },
};
</script>

<script>
import FormInput from '@/components/FormInput';
import IbanInput from '@/components/IbanInput';
import FormSelect from '@/components/FormSelect';
import Loader from '@/loader';
import { gql } from '@apollo/client/core';
import validate from '@/validate';
import notify from '@/notify';
import FriendlyButton from '@/clientcomponents/FriendlyButton';
import { Form } from 'vee-validate';
import Popup from '@/clientcomponents/Popup';

const activationErrorsMap = {
    ALREADY_ACTIVE: 'err-ccs-already-active',
    INVALID: 'err-css-invalid-json',
    INVALID_SIZE: 'err-css-invalid-json',
    INVALID_MISSING: 'err-css-invalid-json',
    INVALID_PATTERN: 'err-css-invalid-json',
    INVALID_UUID: 'err-css-invalid-json',
    INVALID_EMAIL: 'err-css-invalid-json',
    INVALID_VALUE: 'err-css-invalid-json',
    INVALID_LUHN_CHECK: 'err-css-invalid-json',
    INVALID_HANDLER: 'err-server-fail',
    INVALID_JSON: 'err-server-fail',
    UNEXPECTED: 'err-server-fail',
};

const ibanSupportedBanks = ['KREDBEBB', 'CREGBEBB'];

export default {
    name: 'OrderCaro',
    props: {
        clientV2: { type: Object, required: true },
        accountant: { type: Object, required: true },
        visible: { type: Boolean, required: true },
        activation: { type: Object, required: false },
        isNew: { type: Boolean, required: true },
    },
    components: {
        Popup,
        FormInput,
        IbanInput,
        FriendlyButton,
        // eslint-disable-next-line vue/no-reserved-component-names
        Form,
        FormSelect,
    },
    data () {
        const caroSupportedBanks = this.$store.state.allBanks.filter((bank) => bank.isCaroSupported);
        return {
            saving: false,
            caroSupportedBanks,
            isIbanFlowEnabled: caroSupportedBanks.filter((bank) => ibanSupportedBanks.includes(bank.id)).length !== 0,
        };
    },
    computed: {
        isIbanFlow () {
            return ibanSupportedBanks.includes(this.activation.bankBicCode);
        },
        caroSupportedBanksOptions () {
            return this.caroSupportedBanks.map((b) => {
                return {
                    label: b.name,
                    value: b.id,
                };
            });
        },
    },
    methods: {
        closeActivationModal () {
            this.$emit('orderCaroClosed');
        },
        async addActivation (values) {
            Loader.start();
            this.saving = true;
            if (this.isIbanFlow) {
                const validIban = await this.$refs.iban.validate();
                if (!validIban) {
                    Loader.stop();
                    this.saving = false;
                    return;
                }
            }
            try {
                const { data } = await this.$apollo.mutate({
                    mutation: gql`mutation OrderCaro($input: OrderCaroMandateInput!) {
                            orderCaroMandate(input: $input) {
                                errors {
                                    code,
                                    detail,
                                    source {
                                        pointer
                                    }
                                }
                            }
                        }`,
                    variables: {
                        input: {
                            clientId: this.clientV2.id,
                            clientLanguage: this.clientV2.language,
                            accountantLegalName: this.accountant.enterpriseName,
                            clientEmail: values.clientEmail,
                            clientEnterpriseNumber: this.clientV2.enterpriseNumber,
                            clientReference: values.clientReference,
                            clientLegalName: this.clientV2.representativeName,
                            iban: this.isIbanFlow ? values.iban.replace(/\s+/g, '') : null,
                            bankBicCode: values.bankBicCode,
                        },
                    },
                });
                if (data.orderCaroMandate.errors) {
                    validate.reportGQLFieldErrors(data.orderCaroMandate.errors, this.$refs.activationForm, activationErrorsMap);
                    validate.notifyGQLValidationErrors(data.orderCaroMandate.errors, activationErrorsMap);
                    Loader.stop();
                    this.saving = false;
                    return;
                } else {
                    notify.success(this.$t('suc-css-invited'));
                }
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
            Loader.stop();
            this.saving = false;
            this.closeActivationModal();
            this.$emit('caroInvitationSent');
        },
    },
    emits: ['caroInvitationSent', 'orderCaroClosed'],
};
</script>

<template>
    <Form
        ref='activationForm'
        tag='div'
        @submit='addActivation'
    >
        <Popup :show='visible' :close='closeActivationModal' id='order-modal'>
            <template #header>
                <span v-if='isNew'>
                    {{ $t('btn-add-activation') }}
                </span>
                <span v-else>
                    {{ $t('ttl-ccs-popup-edit-activation', { client_reference: activation.clientReference }) }}
                </span>
            </template>
            <p v-if='!isIbanFlowEnabled'>
                {{ $t('p-caro-supported-banks-info') }}
            </p>
            <div>
                <FormSelect
                    v-if='isIbanFlowEnabled'
                    name='bankBicCode'
                    fid='caro-banks-select'
                    type='select'
                    :placeholder='$t("ttl-select-supported-caro-bank")'
                    edit
                    rules='required'
                    :options='caroSupportedBanksOptions'
                    class='mb-6'
                    narrow
                    :value='activation.bankBicCode'
                    @change='v => activation.bankBicCode = v'
                    :label='isIbanFlowEnabled ? $t("ttl-select-supported-caro-bank") : null'
                />
            </div>
            <div>
                <FormInput
                    :value='activation.clientReference'
                    name='clientReference'
                    :label='$t("lbl-ccs-client-reference")'
                    rules='ccsClientReference'
                    fid='client-reference'
                    placeholder='_ _ _ _ _ _ _ _ _ _'
                    :edit='isNew'
                    info='<img src="static/images/client-reference-info.png" width="280" />'
                    info-icon='fa fa-question-circle'
                    class='mb-6'
                    narrow
                />
            </div>
            <p v-if='!isNew'>
                {{ $t('p-caro-activation-edit') }}
            </p>
            <div>
                <FormInput
                    :value='activation.clientEmail'
                    :label='$t("lbl-ccs-client-email")'
                    name='clientEmail'
                    fid='client-email'
                    rules='email|required'
                    :placeholder='$t("lbl-email")'
                    edit
                    class='mb-6'
                    narrow
                />
            </div>
            <div v-if='isIbanFlow'>
                <IbanInput
                    name='iban'
                    ref='iban'
                    :label='$t("lbl-ccs-client-iban")'
                    class='mb-6'
                    :form-ref='$refs.activationForm'
                    :value='activation.iban'
                    narrow
                    rules='required'
                    :check-liquidated='true'
                />
            </div>
            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    :action='closeActivationModal'
                    square
                    extra-small
                    no-margin
                    secondary
                    class='mr-2'
                />
                <FriendlyButton
                    :label='isNew ? "btn-add-activation" : "btn-ccs-popup-edit-activation"'
                    type='submit'
                    :disabled='saving'
                    square
                    extra-small
                    no-margin
                    id='order-caro-modal-button'
                />
            </template>
        </Popup>
    </Form>
</template>

<script setup>
import { resendVerificationCode } from '@/auth/email-verification';

const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    onResendSuccess: {
        type: Function,
        default: null,
    },
    showSuccessMessage: {
        type: Boolean,
        default: true,
    },
});

async function handleResendCode () {
    const result = await resendVerificationCode(
        props.email,
        props.showSuccessMessage,
    );

    if (result.success && props.onResendSuccess) {
        props.onResendSuccess();
    }
}
</script>

<template>
    <div class='resend-verification'>
        <span class='inline-block text-sm text-gray-400'>
            {{ $t('p-verification-check-spam') }}
            <a
                class='inline-block cursor-pointer text-blue-500 hover:text-blue-600 hover:underline'
                @click.stop='handleResendCode'
            >
                {{ $t('btn-verification-resend') }}
            </a>
        </span>
    </div>
</template>

<template>
    <div class='custom-tooltip'>
        <div :class='{faded}'>
            <slot name='trigger'></slot>
        </div>
        <div class='custom-tooltip__content' :class='{"whitespace-nowrap": noWrap, large, left, right, "min-width-auto": minWidthAuto, "min-width-max": minWidthMax, "display-delayed": displayDelayed}'>
            <span class='triangle'>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    version='1.1'
                    viewBox='0 0 160 110'
                >
                    <path d='M 90,10 150,90 30,90 z' />
                </svg>
            </span>
            <slot name='content'></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TooltipComponent',
    props: {
        noWrap: {
            type: Boolean,
            default: false,
        },
        minWidthAuto: {
            type: Boolean,
            default: false,
        },
        minWidthMax: {
            type: Boolean,
            default: false,
        },
        faded: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
        displayDelayed: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
<style lang="postcss" scoped>
.custom-tooltip {
    @apply relative inline-block cursor-default;
}

.faded {
    @apply opacity-75 transition duration-200 ease-in-out;
}

.custom-tooltip__content {
    @apply whitespace-break-spaces shadow-lg bg-gray-800 text-white rounded-md px-6 py-5 inline-block absolute opacity-0 pointer-events-none transition duration-200 ease-in-out max-w-md;
    top: calc(100% + 10px);
    min-width: 300px;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: normal;
}
.custom-tooltip__content :deep(p) {
    @apply text-white;
}
.triangle {
    @apply absolute;
    top: 0;
    left: 50%;
    width: 15px;
    transform: translate(-50%, -7px);
}

.triangle svg path {
    @apply stroke-gray-800 fill-gray-800;
    stroke-width: 20;
    stroke-linejoin: round;
    stroke-linecap:round;
}

.custom-tooltip__content.large {
    min-width: 600px;
}

.custom-tooltip__content.min-width-auto {
    min-width: auto;
}

.custom-tooltip__content.min-width-max {
    min-width: 100%;
}

.custom-tooltip__content.left {
    top: 50%;
    left: auto;
    right: calc(100% + 10px);
    transform: translate(0, -50%);
}

.custom-tooltip__content.left .triangle {
    @apply absolute;
    top: 50%;
    left: auto;
    right: -16px;
    width: 15px;
    transform: translate(-50%, -9px) rotate(90deg);
}

.custom-tooltip__content.right {
    top: 50%;
    right: auto;
    left: calc(100% + 10px);
    transform: translate(0, -50%);
}

.custom-tooltip__content.right .triangle {
    @apply absolute;
    top: 50%;
    right: auto;
    left: -2px;
    width: 15px;
    transform: translate(-50%, -19px) rotate(-90deg);
}

.custom-tooltip:hover {
    z-index: 25;
}

.custom-tooltip:hover .display-delayed {
    @apply delay-500;
}

.custom-tooltip:hover .custom-tooltip__content {
    @apply opacity-100 pointer-events-auto;
}

.custom-tooltip:hover .faded {
    @apply opacity-100;
}
</style>

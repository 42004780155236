<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
    <p>
        {{ t('p-dashboard-feedback-module') }}
    </p>
    <a
        :href='t("link-dashboard-feedback-module-href")'
        target='_blank'
        class='text-blue-400'
    >
        {{ t('link-dashboard-feedback-module-content') }}
    </a>
</template>

<template>
    <div class='text-sm text-grey-600 pl-4 pr-2 py-2 bg-grey-100 border border-solid border-grey-250 rounded transition-colors hover:bg-grey-250 cursor-pointer' @click='copyToClipboard'>
        <div v-if='showTitle' class='mb-1 uppercase text-xs font-bold text-grey-700'>
            {{ $t('lbl-user-settings-2fa-code-title') }}
        </div>
        <div :class='{"flex items-center justify-between": ellipse}'>
            <span class='copy-to-clipboard-text' :class='{"break-all": !ellipse, "truncate": ellipse}'>{{ text }}</span>
            <i class='ml-1 inline-block p-1 fa fa-clipboard'></i>
        </div>
    </div>
</template>

<script setup>
import notify from '@/notify';
import { t } from '@/i18n';

const props = defineProps({
    text: {
        type: String,
        required: true,
    },
    successMessage: {
        type: String,
        default: 'succ-copied-to-clipboard',
    },
    showTitle: {
        type: Boolean,
        default: false,
    },
    ellipse: {
        type: Boolean,
        default: false,
    },
});

async function copyToClipboard () {
    try {
        await navigator.clipboard.writeText(props.text);
        notify.success(t(props.successMessage));
    } catch (err) {
        notify.error(t('err-unknown-retry-later'));
    }
}
</script>

<script>
export default {
    name: 'NotificationBadge',
    props: {
        value: Number,
    },
};
</script>

<template>
    <span
        v-if='value > 0'
        class='bg-red-500 text-white h-6 w-6 rounded-full absolute block z-10 flex justify-center items-center -right-2 -top-2'
    >
        <span class='absolute'>
            {{ value }}
        </span>
    </span>
</template>

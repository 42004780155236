<script setup>
import { computed, watch } from 'vue';
import { useField } from 'vee-validate';
import Tooltip from '@/components/Tooltip';

const props = defineProps({
    value: {
        type: [String, Boolean],
    },
    initialValue: {
        type: [String, Boolean],
    },
    name: {
        type: String,
        required: true,
    },
    label: {
        type: String,
    },
    rules: {
        type: String,
        default: '',
    },
    mode: {
        type: String,
        default: 'eager',
    },
    info: String,
    disabled: {
        type: Boolean,
        default: false,
    },
    edit: {
        type: Boolean,
        default: true,
    },
});

const { value, errorMessage, handleChange } = useField(
    props.name,
    props.rules,
    {
        type: 'radio',
        label: props.label,
        initialValue: props.initialValue,
    },
);

const id = computed(() => {
    return `${props.name}${props.value}`;
});

const emit = defineEmits(['change']);

const onRadioChange = () => {
    emit('change', props.value);
    handleChange(props.value);
};

watch(() => props.value, (newValue) => {
    handleChange(newValue);
});
</script>

<template>
    <div class='form-radio-wrapper' :class='{ "has-error": !!errorMessage, disabled, edit }'>
        <div class='form-radio'>
            <input
                type='radio'
                :value='value'
                @change='onRadioChange'
                :disabled='disabled || !edit'
                :id='id'
                :name='name'
                class='form-radio-input'
            >
            <div
                class='form-radio-input-outer'
                :class='{ "checked": value, "disabled": disabled || !edit }'
            >
                <div class='form-radio-input-inner'></div>
            </div>
        </div>
        <label :for='id' class='form-radio-label'>
            <span class='truncate w-100'>
                <slot></slot>
            </span>
            <span v-if='info' class='ml-2 font-normal'>
                <Tooltip>
                    <template #trigger>
                        <i class='fa fa-info-circle'></i>
                    </template>
                    <template #content>
                        <div>{{ info }}</div>
                    </template>
                </Tooltip>
            </span>
        </label>
        <p class='form-radio-errors' v-show='errorMessage'>
            {{ errorMessage }}
        </p>
    </div>
</template>

<style scoped>
.form-radio-wrapper {
    @apply flex items-center;
}

label.form-radio-label {
    @apply flex items-center cursor-pointer m-0;
    user-select: none;
}

.form-radio-wrapper .form-radio {
    @apply relative flex items-center;
    margin-right: 0.5rem;
}

.form-radio-input {
    @apply opacity-0 absolute inset-0 z-20 cursor-pointer;
}

.form-radio-wrapper .form-radio-input-outer {
    @apply border border-grey-280 rounded-full flex items-center justify-center z-10;
    width: 18px;
    height: 18px;
}

.form-radio-wrapper .form-radio-input-inner {
    @apply bg-white rounded-full opacity-0;
    width: 8px;
    height: 8px;
}

.form-radio-wrapper .form-radio input[type='radio']:checked + .form-radio-input-outer .form-radio-input-inner {
    @apply opacity-100;
}

.form-radio-wrapper .form-radio input[type='radio']:checked + .form-radio-input-outer {
    @apply border-blue-600 bg-blue-600;
}

.form-radio-wrapper .form-radio input[type='radio']:disabled + .form-radio-input-outer {
    @apply border-grey-200 bg-gray-200 cursor-not-allowed;
}

.form-radio-wrapper .form-radio input[type='radio']:disabled + .form-radio-input-outer .form-radio-input-inner {
    @apply bg-gray-300;
}

.form-radio-wrapper .has-error .form-radio-input-outer {
    @apply border-red-300;
}

.form-radio-wrapper .has-error .form-radio-input-inner {
    @apply bg-red-400;
}

.form-radio-wrapper .form-radio-errors {
    @apply text-red-300 ml-0 inline-block font-bold text-sm transition-all mt-1 mb-0 mt-2;
}

.form-radio-wrapper .required {
    @apply text-blue-300;
}

.tooltip {
    @apply ml-2 font-normal;
}
</style>

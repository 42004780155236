<script setup>
import { computed, ref } from 'vue';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import Translated from '@/components/Translated.vue';
import FormStepper from '@/components/FormStepper.vue';
import { Auth } from '@aws-amplify/auth';
import QrcodeVue from 'qrcode.vue';
import notify from '@/notify';
import { Form } from 'vee-validate';
import { t } from '@/i18n';
import FormInput from '@/components/FormInput.vue';
import validate from '@/validate';
import { useStore } from 'vuex';
import CopyToClipBoard from '@/components/CopyToClipBoard.vue';

const store = useStore();

const ROOT = 'root';
const ENABLE_2FA = 'enable2fa';
const DISABLE_2FA = 'disable2fa';

store.dispatch('loadUser');

const popupMode = ref(ROOT);

const userHas2faEnabled = computed(() => store.state.user.mfaMethod === 'SOFTWARE_TOKEN_MFA');

const passwordError = ref('');

let currentUser;
const challenge2FA = ref('');

async function onEnable2faClick () {
    popupMode.value = ENABLE_2FA;
    await setupTOTP();
}

function onDisable2faClick () {
    popupMode.value = DISABLE_2FA;
}
function goBackToRoot () {
    passwordError.value = '';
    popupMode.value = ROOT;
}

async function setupTOTP () {
    currentUser = await Auth.currentAuthenticatedUser();
    challenge2FA.value = await Auth.setupTOTP(currentUser);
}

const stepper = ref(null);

async function confirmTOTP (values, callback) {
    try {
        const response = await Auth.verifyTotpToken(currentUser, values.response);
        if (response.Status && response.Status === 'SUCCESS') {
            await Auth.setPreferredMFA(currentUser, 'TOTP');
            callback();
        }
    } catch (e) {
        // Code mismatch if you enter something wrong
        if (e.message === 'Code mismatch') {
            notify.error(t('err-totp-token-invalid'));
        } else {
            notify.error(t('err-unknown-retry-later'));
        }
    }
}

const QRCodeChallenge = computed(() => `otpauth://totp/MyCodaBox:${store.state.user.email}?secret=${challenge2FA.value}&issuer=MyCodaBox`);

async function disable2fa (values) {
    try {
        const password = values.password;
        const email = store.state.user.email;
        const cognitoUser = await Auth.currentAuthenticatedUser();
        try {
            await Auth.signIn(email, password);
            // Password was correct

            // NOTE: anyone that has access to an authenticated user's browser (physical) can directly execute the line
            // below. as such, it is possible to disable MFA even without providing that user's password.
            // this is not a flaw on our side, nor something that we can prevent. It is the way Cognito operates
            await Auth.setPreferredMFA(cognitoUser, 'NOMFA');

            const currentUser = store.state.user;
            currentUser.mfaMethod = 'NOMFA';
            store.commit('setUser', currentUser);

            notify.success(t('succ-user-settings-2fa-disabled'));
            popupMode.value = ROOT;
        } catch (e) {
            // Password was not correct
            if (e.message && e.message === 'Incorrect username or password.') {
                passwordError.value = t('err-invalid-password');
            } else if (e.message === 'Your software token has already been used once.') {
                passwordError.value = t('err-totp-token-invalid');
            } else if (e.message && e.message === 'Password attempts exceeded') {
                passwordError.value = t('err-login-attempts-exceeded');
            } else {
                passwordError.value = t('err-unknown-retry-later');
            }
        }
    } catch (err) {
        notify.error(t('err-unknown-retry-later'));
    }
}

</script>

<template>
    <!-- 2fa Setting Root-->
    <template v-if='popupMode === ROOT'>
        <div v-if='!userHas2faEnabled'>
            <Translated>
                <template #en>
                    <p class='mb-3 text-grey-500'>
                        If you enable two-factor authentication (2FA), your MyCodabox account will be even more secure.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        When logging in, you will not only have to enter your email address and password, but also a unique verification code that only you can generate via your authentication app.
                        This menu allows you to activate or deactivate 2FA at any time.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/en/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/en/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Read more about 2FA here.
                    </a>
                </template>
                <template #fr>
                    <p class='mb-3 text-grey-500'>
                        Si vous activez l'authentification à deux facteurs (Two-Factor Authentication ou bien 2FA), votre compte MyCodabox sera encore mieux protégé.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        Lorsque vous vous connectez, vous devrez alors saisir votre adresse e-mail et votre mot de passe, mais aussi un code de vérification unique que vous seul pouvez générer via votre application d'authentification.
                        Ce menu vous permet d'activer ou de désactiver la fonction 2FA quand vous le souhaitez.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/fr/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/fr/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Plus d'info sur 2FA.
                    </a>
                </template>
                <template #nl>
                    <p class='mb-3 text-grey-500'>
                        Als je tweestapsverificatie (ook wel Two-Factor Authentication of 2FA) inschakelt, is je MyCodabox account nóg beter beschermd.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        Bij het aanmelden moet je dan niet alleen je e-mailadres en wachtwoord invoeren, maar ook een unieke verificatiecode die alleen jij kan genereren via je authenticatie-app.
                        Via dit menu kan je 2FA inschakelen of uitschakelen wanneer je dat wenst.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/nl/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/nl/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Lees hier meer over 2FA.
                    </a>
                </template>
            </Translated>
            <div class='flex mt-6'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    label='btn-user-settings-2fa-activate'
                    @click='onEnable2faClick'
                />
            </div>
        </div>
        <div v-else>
            <Translated>
                <template #en>
                    <p class='mb-3 text-grey-500'>
                        Two-factor authentication (2FA) is enabled: your account is optimally secured.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        If you disable 2FA, you will only use your email address and password to log in.
                        This menu allows you to activate or deactivate 2FA at any time.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/en/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/en/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Read more about 2FA here.
                    </a>
                </template>
                <template #fr>
                    <p class='mb-3 text-grey-500'>
                        L'authentification à deux facteurs (2FA) est activée : votre compte est donc protégé de manière optimale.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        Si vous désactivez la fonction 2FA, vous n'utiliserez désormais que votre adresse e-mail et votre mot de passe pour vous connecter.
                        Ce menu vous permet d'activer ou de désactiver la fonction 2FA quand vous le souhaitez.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/fr/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/fr/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Plus d'info sur 2FA.
                    </a>
                </template>
                <template #nl>
                    <p class='mb-3 text-grey-500'>
                        Tweestapsverificatie (2FA) is ingeschakeld: je account is dus optimaal beschermd.
                    </p>
                    <p class='mb-3 text-grey-500'>
                        Als je 2FA uitschakelt, zal je voortaan enkel je e-mailadres en wachtwoord gebruiken om aan te melden.
                        Via dit menu kan je 2FA inschakelen of uitschakelen wanneer je dat wenst.
                    </p>
                    <a :href='store.state.isDirectCustomer ? "https://faq.codabox.com/nl/support/solutions/articles/75000123180#2FA_MCB" : "https://faq.codabox.com/nl/support/solutions/articles/75000121311#2FA_MCB"' target='_blank'>
                        Lees hier meer over 2FA.
                    </a>
                </template>
            </Translated>
            <div class='flex mt-6'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    danger
                    label='btn-user-settings-2fa-disable'
                    @click='onDisable2faClick'
                />
            </div>
        </div>
    </template>
    <!-- 2fa Setting Enable -->
    <template v-else-if='popupMode === ENABLE_2FA'>
        <h2 class='flex mt-0 mb-10 text-4xl'>
            {{ $t('ttl-user-settings-2fa-manage') }}
            <span class='text-grey-250 mx-2'>/</span>
            {{ $t('lbl-user-settings-2fa-enable') }}
        </h2>
        <FormStepper
            :steps='[
                {
                    key: "step-1",
                    title: t("user-settings-2fa-enable-step-1"),
                },
                {
                    key: "step-2",
                    title: t("user-settings-2fa-enable-step-2"),
                },
                {
                    key: "step-3",
                    title: t("user-settings-2fa-enable-step-3"),
                }
            ]'
            v-slot='{ currentStep, nextStep }'
            ref='stepper'
        >
            <template v-if='currentStep === "step-1"'>
                <div class='flex items-center mt-12 w-11/12 mx-auto h-72'>
                    <div class='mb-6 max-w-64 mr-12 pr-12 border-b-0 border-l-0 border-t-0 border-grey-100 border-solid h-full flex flex-col justify-center items-center' v-if='challenge2FA'>
                        <qrcode-vue :size='150' :value='QRCodeChallenge' class='my-3' />
                        <CopyToClipBoard id='2fa-challenge-key' :text='challenge2FA' success-message='succ-user-settings-2fa-key-copied' :show-title='true' />
                    </div>
                    <div>
                        <p class='mb-3 text-xl font-bold'>
                            <Translated>
                                <template #en>
                                    The authentication app will generate the verification code you need in addition to your password, to log in to MyCodabox.
                                </template>
                                <template #fr>
                                    L'application d'authentification génère le code de vérification dont vous avez besoin en plus de votre mot de passe pour vous connecter à MyCodabox.
                                </template>
                                <template #nl>
                                    De authenticatie-app zal de verificatiecode genereren die je nodig hebt naast je wachtwoord, om in te loggen op MyCodabox.
                                </template>
                            </Translated>
                        </p>
                        <p class='text-grey-400'>
                            <Translated>
                                <template #en>
                                    Don't have an authentication app yet? <br>
                                    Download Microsoft Authenticator, Google Authenticator or any other authentication app to enable 2FA.
                                </template>
                                <template #fr>
                                    Vous n'avez pas encore d'application d'authentification ? <br>
                                    Téléchargez Microsoft Authenticator, Google Authenticator ou toute autre application d'authentification pour activer 2FA.
                                </template>
                                <template #nl>
                                    Heb je nog geen authenticatie-app? <br>
                                    Download Microsoft Authenticator, Google Authenticator of een andere authenticatie-app om 2FA in te schakelen.
                                </template>
                            </Translated>
                        </p>
                    </div>
                </div>
                <div class='flex justify-end mt-6'>
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        secondary
                        label='btn-cancel'
                        class='mr-3'
                        @click='goBackToRoot'
                    />
                    <FriendlyButton
                        square
                        extra-small
                        no-margin
                        label='btn-next'
                        @click='nextStep'
                    />
                </div>
            </template>
            <template v-if='currentStep === "step-2"'>
                <Form tag='div' v-slot='{ values, handleSubmit }'>
                    <div class='flex items-center justify-center my-12 w-11/12 mx-auto h-28'>
                        <div class='mr-12 pr-12 border-b-0 border-l-0 border-t-0 border-grey-100 border-solid h-full flex items-center' v-if='challenge2FA'>
                            <div class='w-56'>
                                <FormInput
                                    name='response'
                                    :placeholder='$t("placeholder-totp-input")'
                                    rules='TOTPFormat|max:6|min:6|integer|required'
                                    edit
                                    id='response'
                                    :formatter='str => validate.formatString(str, "DDDDDD")'
                                    format-on-type
                                />
                            </div>
                        </div>
                        <p class='text-xl font-bold m-0'>
                            <Translated>
                                <template #en>
                                    Enter the 6-digit verification code generated by your authentication app.
                                </template>
                                <template #fr>
                                    Entrez le code de vérification à 6 chiffres généré par votre application d'authentification.
                                </template>
                                <template #nl>
                                    Vul de 6-cijferige verificatiecode in die is gegenereerd door jouw authenticatie-app
                                </template>
                            </Translated>
                        </p>
                    </div>
                    <div class='mt-6 flex justify-end'>
                        <FriendlyButton
                            square
                            extra-small
                            no-margin
                            secondary
                            label='btn-cancel'
                            class='mr-3'
                            @click='goBackToRoot'
                        />
                        <FriendlyButton
                            label='lbl-user-settings-2fa-enable'
                            no-margin
                            fullwidth
                            square
                            extra-small
                            type='submit'
                            @click.prevent='handleSubmit($event, () => { confirmTOTP(values, nextStep)})'
                        />
                    </div>
                </Form>
            </template>
            <template v-if='currentStep === "step-3"'>
                <div class='text-center'>
                    <p class='mb-4'>
                        <i class='fa fa-lock text-6xl text-green-300'></i>
                    </p>
                    <p class='text-xl font-bold m-0'>
                        <Translated>
                            <template #en>
                                Two-factor authentication is enabled for your MyCodabox account!
                            </template>
                            <template #fr>
                                L'authentification à deux facteurs est activée pour votre compte MyCodabox !
                            </template>
                            <template #nl>
                                Twee-factor authenticatie is ingeschakeld voor jouw MyCodabox account!
                            </template>
                        </Translated>
                    </p>
                    <p class='my-4 w-2/3 mx-auto'>
                        <Translated>
                            <template #en>
                                You've added an additional layer of security to your account. From now on in addition to your MyCodabox password, you'll also need to provide the verification code when logging in.
                            </template>
                            <template #fr>
                                Vous avez ajouté une couche de sécurité supplémentaire à votre compte. Désormais, en plus de votre mot de passe MyCodabox, vous devrez également fournir le code de vérification lorsque vous vous connecterez.
                            </template>
                            <template #nl>
                                Je hebt een extra beveiligingslaag toegevoegd aan je account. Vanaf nu moet je naast je MyCodabox wachtwoord ook de verificatiecode invoeren als je inlogt.
                            </template>
                        </Translated>
                    </p>
                </div>
            </template>
        </FormStepper>
    </template>
    <template v-else-if='popupMode === DISABLE_2FA'>
        <h2 class='flex mt-0 mb-10 text-4xl'>
            {{ $t('ttl-user-settings-2fa-manage') }}
            <span class='text-grey-250 mx-2'>/</span>
            {{ $t('lbl-user-settings-2fa-disable') }}
        </h2>
        <Translated>
            <template #en>
                <p class='font-bold'>
                    Enter your MyCodabox password to confirm.
                </p>
                <p class='mb-3 text-grey-500'>
                    After disabling 2FA you will no longer be required to enter a verification code when you log in.
                </p>
            </template>
            <template #fr>
                <p class='font-bold'>
                    Entrez votre mot de passe MyCodabox pour confirmer
                </p>
                <p class='mb-3 text-grey-500'>
                    Après avoir désactivé le 2FA, vous ne serez plus obligé d'entrer un code de vérification lorsque vous vous connectez.
                </p>
            </template>
            <template #nl>
                <p class='font-bold'>
                    Voer je MyCodabox wachtwoord in om te bevestigen.
                </p>
                <p class='mb-3 text-grey-500'>
                    Na het uitschakelen van 2FA hoef je geen verificatiecode meer in te voeren als je inlogt.
                </p>
            </template>
        </Translated>
        <Form @submit='disable2fa' v-slot='{ values }'>
            <div v-if='passwordError' class='rounded-md bg-red-400 text-white mb-4 px-6 py-3'>
                {{ passwordError }}
            </div>
            <div class='max-w-80 mt-6'>
                <FormInput
                    name='password'
                    :placeholder='$t("lbl-password")'
                    rules='required'
                    type='password'
                    id='password'
                    edit
                />
            </div>
            <div class='mt-6 flex justify-end'>
                <FriendlyButton
                    square
                    extra-small
                    no-margin
                    secondary
                    label='btn-cancel'
                    class='mr-3'
                    @click='goBackToRoot'
                />
                <!--
                          I'm not sure the disabled is useful
                          we could also check if password is strong enough, but that's not very clear for the user
                    -->
                <FriendlyButton
                    label='lbl-user-settings-2fa-disable'
                    no-margin
                    fullwidth
                    square
                    extra-small
                    type='submit'
                    :disabled='values.password?.length === 0'
                />
            </div>
        </Form>
    </template>
</template>

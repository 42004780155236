<script setup>
import { defineEmits, defineProps, computed } from 'vue';
import ContentBox from '@/components/ContentBox';
import CustomTitle from '@/components/Title';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import DOMPurify from 'dompurify';

const props = defineProps({
    icon: String,
    iconColor: String,
    title: {
        type: String,
        required: true,
    },
    titleInfo: String,
    closable: {
        type: Boolean,
        default: true,
    },
    size: {
        type: String,
        default: 'col-span-3',
    },
    iconPulse: Boolean,
    hideMenu: Boolean,
});

const pairedIconColors = computed(() => {
    const colors = {};

    switch (props.iconColor) {
        case 'blue':
            colors.background = 'bg-blue-300';
            colors.text = 'text-blue-300';
            break;
        case 'green':
            colors.background = 'bg-green-200';
            colors.text = 'text-green-600';
            break;
        case 'red':
            colors.background = 'bg-red-200';
            colors.text = 'text-red-500';
            break;
        default:
            colors.background = 'bg-gray-200';
            colors.text = 'text-gray-500';
    }

    return colors;
});

const emit = defineEmits(['closed', 'resize']);

const handleClose = () => {
    emit('closed');
};

const handleResize = (size) => {
    emit('resize', size);
};

const isCurrentSize = (size) => {
    return props.size === size;
};

function sanitize (html) {
    return DOMPurify.sanitize(html);
};
</script>>

<template>
    <section class='relative' :class='size'>
        <content-box small-paddings>
            <header class='flex items-center mb-3'>
                <div class='-mt-1 flex items-center w-full pr-16'>
                    <Icon
                        :name='props.icon'
                        class='mr-3 flex-shrink-0'
                        :class='pairedIconColors.text'
                        featured
                        width='w-5'
                        :featured-bg-color='pairedIconColors.background'
                        :pulse='props.iconPulse'
                    />
                    <CustomTitle
                        :level='2'
                        :style-of='4'
                        no-default-margins
                        class='truncate'
                    >
                        <i :class='props.icon'></i> {{ props.title }}
                    </CustomTitle>
                    <template v-if='props.titleInfo'>
                        <Tooltip class='p-2 flex-shrink-0'>
                            <template #trigger>
                                <Icon name='InformationCircle' width='w-4' class='text-gray-300' />
                            </template>
                            <template #content>
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <span v-html='sanitize(props.titleInfo)'></span>
                            </template>
                        </Tooltip>
                    </template>
                </div>
                <div class='dashboard-card__menu' v-if='!props.hideMenu'>
                    <span class='dashboard-card__resize-button' v-if='closable'>
                        <Icon name='ViewColumns' width='w-5' class='text-grey-300' />
                        <div class='dashboard-card__resize-menu shadow-lg'>
                            <div
                                class='dashboard-card__resize-option'
                                :class='{ "dashboard-card__resize-option--current": isCurrentSize("col-span-6") }'
                                @click='handleResize("col-span-6")'
                            >
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                            </div>
                            <div
                                class='dashboard-card__resize-option'
                                :class='{ "dashboard-card__resize-option--current": isCurrentSize("col-span-4") }'
                                @click='handleResize("col-span-4")'
                            >
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                            </div>
                            <div
                                class='dashboard-card__resize-option'
                                :class='{ "dashboard-card__resize-option--current": isCurrentSize("col-span-3") }'
                                @click='handleResize("col-span-3")'
                            >
                                <div class='dashboard-card__resize-option__bar'></div>
                                <div class='dashboard-card__resize-option__bar'></div>
                            </div>
                            <div
                                class='dashboard-card__resize-option'
                                :class='{ "dashboard-card__resize-option--current": isCurrentSize("col-span-2") }'
                                @click='handleResize("col-span-2")'
                            >
                                <div class='dashboard-card__resize-option__bar'></div>
                            </div>
                        </div>
                    </span>
                    <span class='dashboard-card__close group' @click='handleClose' v-if='closable'>
                        <div class='relative w-5 h-5'>
                            <Icon
                                name='Eye'
                                width='w-5'
                                class='text-gray-400 top-0 left-0 absolute hover:gray-700 group-hover:opacity-0'
                            />
                            <Icon
                                name='EyeSlash'
                                width='w-5'
                                class='text-gray-400 top-0 left-0 hover:gray-700 absolute opacity-0 group-hover:opacity-100'
                            />
                        </div>
                    </span>
                </div>
            </header>
            <div>
                <slot></slot>
            </div>
        </content-box>
    </section>
</template>

<style lang='postcss' scoped>
.dashboard-card__menu {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  display: flex;
}

.dashboard-card__resize-button {
  position: relative;
  @apply text-grey-400 h-10 w-10 transition-all text-lg hover:bg-blue-100 flex items-center justify-center rounded-md;
  cursor: pointer;
  user-select: none;
}

.dashboard-card__resize-menu {
    @apply hidden absolute top-10 right-0 bg-white rounded-md z-10 p-3;
    width: 87px;
    height: 40px;
}

.dashboard-card__resize-option {
    @apply absolute flex cursor-pointer whitespace-nowrap;
}

.dashboard-card__resize-option__bar {
  @apply w-4 h-6 bg-gray-300 mr-1 rounded-sm;
}

.dashboard-card__resize-option:hover div {
    @apply bg-grey-700 z-10 pointer-events-auto;
}

.dashboard-card__resize-button:hover .dashboard-card__resize-menu {
    @apply flex;
}

.dashboard-card__close {
  @apply text-grey-400 cursor-pointer h-10 w-10 transition-all text-lg hover:bg-blue-100 flex items-center justify-center rounded-md;
  user-select: none;
}

.dashboard-card__resize-option--current .dashboard-card__resize-option__bar {
    @apply bg-gray-500 pointer-events-none;
    z-index: 9;
}
</style>

<script>
    export default {
        props: {
            hasProblem: {
                type: Boolean,
                default: false,
            },
        },
        computed: {

        },
        methods: {
        },

    };
</script>

<template>
    <div class='flex'>
        <span class='w-2 mt-1 mr-2 bg-red-200 inline-block rounded-md shrink-0' v-if='hasProblem'></span>
        <div style='width:100%'>
            <slot></slot>
        </div>
    </div>
</template>

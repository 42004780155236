<script>
    import Dropdown from '@/components/DropdownV2';
    import DropdownGroup from '@/components/DropdownGroup';
    import DropdownItem from '@/components/DropdownItem';

    export default {
        name: 'SelectorComponent',
        props: {
            filter: Boolean,
            model:  {
                type: Object,
                required: true,
            },
        },
        components: {
            Dropdown,
            DropdownGroup,
            DropdownItem,
        },
        computed: {
            options () {
                if (this.model) {
                    return this.model.options;
                } else {
                    return [];
                }
            },
            isActive () {
                return this.filter && this.model.selected !== this.model.options[0].value;
            },
        },
    };
</script>

<template>
    <Dropdown
        v-model='model.selected'
        default-value='csv'
        direction='right'
        :name='label'
    >
        <DropdownGroup>
            <DropdownItem v-for='option in options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                {{ $t(option.label) }}
            </DropdownItem>
        </DropdownGroup>
    </Dropdown>
</template>

<script>
    import Api from '../api';
    import utils from '../utils.js';
    import notify from '../notify.js';
    import Loader from '../loader.js';
    import WizardTransitions from '../clientcomponents/WizardTransitions.vue';
    import WizardScreen from '../clientcomponents/WizardScreen.vue';
    import WizardBox from '../clientcomponents/WizardBox.vue';
    import FriendlyButton from '../clientcomponents/FriendlyButton.vue';
    import FriendlyInputFuzzy from '../clientcomponents/FriendlyInputFuzzy.vue';
    import ButtonBar from '../clientcomponents/ButtonBar.vue';
    import Popup from '../clientcomponents/Popup.vue';
    import BreadCrumbs from '../clientcomponents/BreadCrumbs.vue';
    import NavBar from '@/components/NavBar.vue';
    import Translated from '@/components/Translated';
    import FormInput from '@/components/FormInput.vue';

    export default {
        name: 'PurchaseWizard',
        components: {
            FormInput,
            WizardTransitions,
            WizardScreen,
            WizardBox,
            FriendlyButton,
            FriendlyInputFuzzy,
            ButtonBar,
            Popup,
            BreadCrumbs,
            NavBar,
            Translated,
        },
        data () {
            return {
                step: 'verification',
                showRefuseConfirmation: false,
                showRetryMessage: false,
                response: [''],
                history: ['verification'],
                nameResponses: [],
                errorMessage: null,
                showBreadcrumps: false,
                loaded: false,
                state: '',
                remainingAttempts: 0,
                legalEntity: {},
                challengeAnswerRequired: null,
                email: '',
                accountancyFirm: '',
            };
        },
        computed: {
            mobile () {
                return this.$store.state.gui !== 'widescreen';
            },
            locale () {
                return this.$i18n.locale;
            },
            wizardSteps () {
                return [
                    {
                        keys: ['verification'],
                        label: 'lbl-step-verification',
                    }, {
                        keys: ['done'],
                        label: 'lbl-step-done',
                        freeze: ['verification'],
                    },
                ];
            },
            canConfirm () {
                return this.accountancyFirm && this.nameResponses.includes(this.accountancyFirm);
            },
            legalEntityNameWithEnterpriseNumber () {
                return this.legalEntity.enterprise_num ? `${this.legalEntity.name} (${this.legalEntity.enterprise_num})` : this.legalEntity.name;
            },
        },
        watch: {
            step: function (step) {
                if (step === 'done') {
                    utils.remove(this.history, 'verification');
                }
            },
        },
        created: function () {
            this.getEmailVerification();
        },
        methods: {
            getEmailVerification () {
                Loader.start();
                Api.getEmailVerification(this.$route.params.token)
                    .then((data) => {
                        this.nameResponses = data.challenge_fiduciary_name;
                        this.email = data.email;
                        this.legalEntity = data.client_legal_entity;
                        this.challengeAnswerRequired = data.challenge_answer_required;
                        this.state = data.state;
                        switch (this.state) {
                            case 'verification-sent':
                                this.showBreadcrumps = true;
                                break;
                            case 'reminder-sent':
                                this.showBreadcrumps = true;
                                break;
                            case 'verified-email':
                                if (this.$route.meta.entry === 'refuse') {
                                    this.state = 'already-validated';
                                }
                                this.nextStep('done');
                                break;
                            case 'verified-pi-mandate':
                                if (this.$route.meta.entry === 'refuse') {
                                    this.state = 'already-validated';
                                }
                                this.nextStep('done');
                                break;
                            case 'failed-refused':
                                if (this.$route.meta.entry === 'accept') {
                                    this.state = 'already-refused';
                                }
                                this.nextStep('done');
                                break;
                            default:
                                this.nextStep('done');
                        }
                    })
                    .catch(error => {
                        if (error.status === 404) {
                            this.state = 'failed-expired';
                        }
                        this.nextStep('done');
                        this.showBreadcrumps = false;
                    })
                    .finally(() => {
                        this.loaded = true;
                        Loader.stop();
                    });
            },
            submitEmailVerification () {
                Loader.start();

                Api.submitEmailVerification(this.$route.params.token, 'verified-email', this.accountancyFirm)
                    .then((data) => {
                        this.state = data.state;
                        this.remainingAttempts = data.remaining_attempts;
                    })
                    .catch(error => {
                        if (error.status === 400) {
                            if (error.body.non_field_errors && error.body.non_field_errors[0] === 'The email is already refused') {
                                this.state = 'already-refused';
                            } else {
                                this.remainingAttempts = error.body.remaining_attempts;
                                this.showRetryMessagePopup();
                                this.accountancyFirm = '';
                            }
                        } else {
                            notify.error(this.$t('err-unknown'));
                        }
                    })
                    .finally(() => {
                        Loader.stop();
                        if (this.remainingAttempts === 0 || this.state === 'verified-email') {
                            this.showRetryMessage = false;
                            this.nextStep('done');
                        }
                    });
            },
            refuseVerification () {
                Loader.start();
                Api.submitEmailVerification(this.$route.params.token, 'failed-refused')
                    .then((data) => {
                        this.state = data.state;
                    })
                    .catch(error => {
                        if (error.status === 400 && error.body.non_field_errors[0] === 'The email is already verified') {
                            this.state = 'already-validated';
                        }
                    })
                    .finally(() => {
                        Loader.stop();
                        this.nextStep('done');
                        this.closeRefuseConfirmationPopup();
                    });
            },
            closeRefuseConfirmationPopup () {
                this.showRefuseConfirmation = false;
            },
            showRefuseConfirmationPopup () {
                this.showRefuseConfirmation = true;
            },
            showRetryMessagePopup () {
                this.showRetryMessage = true;
            },
            nextStep (step) {
                this.step = step;
                setTimeout(() => {
                    utils.scrollTop();
                }, 150);
            },
            validateName (value) {
                if (value.length > 0 && this.nameResponses.find((elem) => {
                    return elem === value;
                })) {
                    return { state: 'valid' };
                } else {
                    return { state: 'incomplete' };
                }
            },
        },
    };
</script>

<template>
    <div>
        <NavBar no-nav no-login />
        <Popup :show='showRefuseConfirmation' :close='closeRefuseConfirmationPopup'>
            <template #header>
                {{ $t('h-are-you-sure') }}
            </template>
            <template #alert>
            </template>
            <template #buttons>
                <FriendlyButton
                    label='btn-cancel'
                    :action='closeRefuseConfirmationPopup'
                    symbol='times'
                    secondary
                    square
                />
                <FriendlyButton
                    label='btn-refuse-mandate'
                    :action='refuseVerification'
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <WizardTransitions>
            <BreadCrumbs
                :steps='wizardSteps'
                :history='history'
                v-model='step'
                v-if='showBreadcrumps'
                key='breadcrumbs'
            />
        </WizardTransitions>

        <WizardTransitions v-show='loaded'>
            <WizardScreen
                key='verification'
                v-if='step === "verification" && challengeAnswerRequired && $route.meta.entry === "accept"'
                class='flex-col justify-start'
            >
                <WizardBox animated class='mt-12' v-if='$route.meta.entry === "accept"'>
                    <div class='mb-6 md:mb-12 md:font-light text-center text-4xl md:text-6xl'>
                        {{ $t('lbl-email-question-title') }}
                    </div>
                    <div class='font-light text-center text-xl'>
                        <Translated>
                            <template #en>
                                You hereby confirm that your email address is linked to <span class='font-black'>{{ legalEntity.name }}</span>.<br>
                                As a final verification step we would like to ask you to enter the name of your accountancy firm.
                            </template>
                            <template #nl>
                                Je bevestigt hierbij dat jouw e-mailadres gelinkt is aan onderneming <span class='font-black'>{{ legalEntity.name }}</span>.<br>
                                Als laatste verificatiestap willen wij je vragen om de naam van jouw accountancykantoor in te vullen.
                            </template>
                            <template #fr>
                                Vous confirmez par la présente que votre adresse e-mail est liée à <span class='font-black'>{{ legalEntity.name }}</span>.<br>
                                En guise de vérification finale, nous vous demandons de saisir le nom de votre bureau comptable.
                            </template>
                        </Translated>
                    </div>
                    <ButtonBar thinner>
                        <FriendlyInputFuzzy
                            :value='accountancyFirm'
                            @set='value => { accountancyFirm = value; }'
                            :list='nameResponses'
                        >
                            <template #input>
                                <FormInput
                                    ref='accountantInput'
                                    name='accountantName'
                                    id='accountantName'
                                    :label='$t("lbl-accountant-name")'
                                    edit
                                    :value='accountancyFirm'
                                    @input='value => accountancyFirm = value'
                                />
                            </template>
                        </FriendlyInputFuzzy>

                        <p v-if='showRetryMessage' class='remaining-attempts'>
                            <Translated>
                                <template #en>
                                    This information is not correct. You still have <span class='font-black'>{{ remainingAttempts }} attempt(s)</span>.
                                </template>
                                <template #nl>
                                    De gegevens zijn niet correct. Je hebt nog <span class='font-black'>{{ remainingAttempts }} poging(en)</span>.
                                </template>
                                <template #fr>
                                    Les données ne sont pas correctes. Vous avez encore <span class='font-black'>{{ remainingAttempts }} tentative(s)</span>.
                                </template>
                            </Translated>
                        </p>
                    </ButtonBar>
                    <ButtonBar thinner>
                        <FriendlyButton
                            label='btn-confirm'
                            :action='submitEmailVerification'
                            symbol='arrow-right'
                            square
                            :disabled='!canConfirm'
                        />
                    </ButtonBar>
                </WizardBox>
            </WizardScreen>

            <WizardScreen
                key='verification'
                v-if='step === "verification" && !challengeAnswerRequired && $route.meta.entry === "accept"'
                class='flex-col justify-start'
            >
                <WizardBox animated class='mt-6 md:mt-12'>
                    <div class='mb-6 md:mb-12 font-light text-center text-4xl md:text-6xl'>
                        <Translated>
                            <template #en>
                                Does this email belong to <br><span class='font-black'>{{ legalEntity.name }}</span>?
                            </template>
                            <template #nl>
                                Is dit e-mailadres gelinkt aan <br><span class='font-black'>{{ legalEntity.name }}</span>?
                            </template>
                            <template #fr>
                                Est-ce que cette adresse est liée à <br><span class='font-black'>{{ legalEntity.name }}</span> ?
                            </template>
                        </Translated>
                    </div>
                    <div class='font-light text-center text-xl'>
                        <Translated>
                            <template #en>
                                You hereby confirm that <span class='font-black'>{{ email }}</span> is linked to <span class='font-black'>{{ legalEntityNameWithEnterpriseNumber }}</span>.
                            </template>
                            <template #nl>
                                Je bevestigt hierbij dat <span class='font-black'>{{ email }}</span> gelinkt is aan <span class='font-black'>{{ legalEntityNameWithEnterpriseNumber }}</span>.
                            </template>
                            <template #fr>
                                Vous confirmez par la présente que <span class='font-black'>{{ email }}</span> est liée à <span class='font-black'>{{ legalEntityNameWithEnterpriseNumber }}</span>.
                            </template>
                        </Translated>
                    </div>
                    <ButtonBar>
                        <FriendlyButton
                            label='btn-confirm'
                            :action='submitEmailVerification'
                            symbol='arrow-right'
                            square
                        />
                    </ButtonBar>
                </WizardBox>
            </WizardScreen>

            <WizardScreen
                key='verification'
                v-if='step === "verification" && $route.meta.entry === "refuse"'
                class='flex-col justify-start'
            >
                <WizardBox animated class='mt-12'>
                    <div class='mb-12 font-light text-center text-4xl md:text-6xl'>
                        {{ $t('lbl-email-refuse-confirm-title') }}
                    </div>
                    <div class='font-light text-center text-xl'>
                        {{ $t('lbl-email-refuse-confirm-body') }}
                    </div>
                    <ButtonBar>
                        <FriendlyButton
                            label='btn-refuse'
                            :action='showRefuseConfirmationPopup'
                            symbol='arrow-right'
                            square
                        />
                    </ButtonBar>
                </WizardBox>
            </WizardScreen>

            <WizardScreen
                key='done'
                v-if='step === "done"'
                :class='showBreadcrumps ? "flex-col justify-start" : ""'
            >
                <WizardBox animated-long v-if='state === "failed-expired"'>
                    <div class='mt-12 font-light text-center mb-6 md:mb-12 text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-expired-title') }}
                        </span>
                    </div>
                    <div class='mt-12 text-center text-2xl'>
                        <Translated>
                            <template #en>
                                The link has expired and can no longer be used. Please contact <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> if you want to receive a new link.
                            </template>
                            <template #nl>
                                De link is vervallen en kan niet langer gebruikt worden. Gelieve <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> te contacteren als je een nieuwe link wenst te ontvangen.
                            </template>
                            <template #fr>
                                Ce lien est expiré et ne peut plus être utilisé. Veuillez contacter <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> si vous voulez recevoir un nouveau lien.
                            </template>
                        </Translated>
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else-if='state === "verified-email" || state === "verified-pi-mandate"'>
                    <div class='mt-12 font-light text-center text-4xl md:text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-verification-success-title') }} 🎉
                        </span>
                    </div>
                    <div class='mt-12 text-center text-xl'>
                        {{ challengeAnswerRequired ? $t('lbl-email-verification-success-body') : $t('lbl-email-verification-no-challenge-success-body') }}
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else-if='state === "failed-refused"'>
                    <div class='mt-12 font-light text-center text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-refuse-success-title') }}
                        </span>
                    </div>
                    <div class='mt-12 text-center text-xl'>
                        {{ $t('lbl-email-refuse-success-body') }}
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else-if='state === "already-validated"'>
                    <div class='mt-12 font-light text-center text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-already-validated-title') }}
                        </span>
                    </div>
                    <div class='mt-12 text-center text-2xl'>
                        {{ $t('lbl-email-already-validated-body') }}
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else-if='state === "already-refused"'>
                    <div class='mt-12 font-light text-center text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-already-refused-title') }}
                        </span>
                    </div>
                    <div class='mt-12 text-center text-2xl'>
                        {{ $t('lbl-email-already-refused-body') }}
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else-if='state === "failed-challenge"'>
                    <div class='mt-12 font-light text-center text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-verification-fail-title') }}
                        </span>
                    </div>
                    <div class='mt-12 text-center text-2xl'>
                        <Translated>
                            <template #en>
                                You did not correctly identify your accountancy firm. You will not be able to receive your available files from Codabox.
                                Please contact <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> if you still want to verify your email address.
                                We will send you a new link.
                            </template>
                            <template #nl>
                                Je hebt jouw accountancykantoor niet correct geïdentificeerd. Je zal niet in staat zijn om jouw beschikbare bestanden van Codabox te ontvangen.
                                Gelieve <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> te contacteren als je alsnog wenst jouw e-mailadres te verifiëren.
                                We zullen je een nieuwe link sturen.
                            </template>
                            <template #fr>
                                Vous n'avez pas correctement identifié votre bureau comptable. Vous ne pourrez pas recevoir les documents disponibles chez Codabox.
                                Veuillez contacter <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a> si vous voulez toujours faire vérifier votre adresse e-mail.
                                Nous vous enverrons un nouveau lien.
                            </template>
                        </Translated>
                    </div>
                </WizardBox>
                <WizardBox animated-long v-else>
                    <div class='mt-12 font-light text-center text-6xl'>
                        <span class='font-black'>
                            {{ $t('lbl-email-verification-fail-title') }}
                        </span>
                    </div>
                </WizardBox>
            </WizardScreen>
        </WizardTransitions>
    </div>
</template>

<style lang="scss" scoped>
    .remaining-attempts {
        color: $error-color;
        margin-top: 20px;
    }
</style>

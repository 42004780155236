<script>
    import utils from '@/utils.js';
    import { mapState } from 'vuex';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Popup from '@/clientcomponents/Popup';

    export default {
        name: 'PrismicPopup',
        props: {
            space: {
                type: String,
                required: true,
            },
        },
        components: {
            FriendlyButton,
            Popup,
        },
        data () {
            return {
                showPopup: true,
            };
        },
        computed: {
            noPrismic () {
                return this.$store.state.flags.noprismic;
            },
            popup () {
                if (!this.$store.state.popups.loaded) {
                    return null;
                } else {
                    const debug  = !!this.$store.state.flags.debug;
                    const popups = this.$store.state.popups.navigation;
                    const seen   = this.loadSeenPopups();
                    for (const popup of popups) {
                        if (!popup[this.space]) {
                            continue;
                        }
                        if (!debug && !popup.prod) {
                            continue;
                        }
                        if (!seen[popup.slug]) {
                            return popup;
                        }
                    }
                    return null;
                }
            },
            popupContent () {
                if (!this.popup) {
                    return {};
                }
                const documentId = this.popup[this.$i18n.locale];
                const doc = this.$store.state.popups.articles[documentId];
                if (!doc) {
                    console.error('Missing Prismic Document:', documentId);
                    return {};
                }

                return {
                    title: utils.prismicDomToText(doc.data['popup-title']),
                    body:  utils.prismicDomToHtml(this.$store, this.space, doc.data['popup-body']),
                };
            },
            ...mapState(['popups']),
        },
        mounted () {
            this.$store.dispatch('loadPrismicPopups');
        },
        methods: {
            close () {
                this.showPopup = false;
                if (this.popup) {
                    const email = this.$store.state.user.email;
                    const key   = 'prismic_popup_' + email;
                    const seen  = JSON.parse(localStorage[key] || '{}');
                    seen[this.popup.slug] = true;
                    localStorage[key] = JSON.stringify(seen);
                }
            },
            loadSeenPopups () {
                const email = this.$store.state.user.email;
                const key   = 'prismic_popup_' + email;
                const seen  = JSON.parse(localStorage[key] || '{}');
                return seen;
            },
        },
    };
</script>

<template>
    <Popup :show='showPopup' :close='close' v-if='popup && popups.visible && !noPrismic && !$store.state.tutorials.tutorialInProgress'>
        <template #header>
            <div>
                {{ popupContent.title }}
            </div>
        </template>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html='popupContent.body'></div>
        <template #buttons>
            <FriendlyButton
                :action='close'
                label='btn-close'
                extra-small
                no-margin
                square
            />
        </template>
    </Popup>
</template>

import { Auth } from '@aws-amplify/auth';
import notify from '@/notify';
import { t } from '@/i18n';
import { datadogRum } from '@datadog/browser-rum';

/**
 * Attempts to resend a verification code to an email
 *
 * @param {string} email - The email to send the verification code to
 * @param {boolean} showSuccess - Whether to show a success notification
 * @returns {Promise<Object>} - Promise resolving to an object with success status
 */
export async function resendVerificationCode (email, showSuccess = true) {
    if (!email) {
        return {
            success: false,
            message: 'No email provided',
        };
    }

    try {
        await Auth.resendSignUp(email);

        if (showSuccess) {
            notify.success(`${t('succ-verification-resend')} ${email}.`);
        }

        datadogRum.addAction('email_verification_code_resent');

        return {
            success: true,
        };
    } catch (error) {
        // User doesn't exist, we stay silent about it
        if (error.code === 'UserNotFoundException') {
            return {
                success: false,
            };
        }

        // Rate limiting
        if (error.code === 'LimitExceededException' || error.name === 'LimitExceededException') {
            notify.error(t('err-verification-attempts-exceeded'));
            return {
                success: false,
            };
        }

        // Any other error
        notify.error(t('err-unknown'));
        return {
            success: false,
            error,
        };
    }
}

export default {
    resendVerificationCode,
};

<script>
    export default {
        name: 'CcsTermsFrenchView',
        props: {
            isDirectCustomer: Boolean,
        },
    };
</script>

<template>
    <div>
        <h3 class='cb-service-main-title'>
            Description du service
        </h3>

        <p>
            Grâce au service CARO, vous pouvez recevoir directement et numériquement des relevés de cartes de crédit professionnelles, pour autant que le titulaire du compte et le détenteur de la carte aient donné leur autorisation.
            Vous disposez ainsi toujours d’une vue d'ensemble complète et pouvez facilement et correctement réconcilier les différentes transactions.
            Vous recevez toujours, en temps opportun, tous les relevés de cartes de crédit pertinents et vous ne devez plus les saisir manuellement.
        </p>

        <p>
            Les fichiers CARO sont livrés mensuellement, conformément à la période comptable de l'émetteur de la carte de crédit, pour autant qu’il y ait au moins 1 paiement effectué avec la carte de crédit au cours de cette période.
        </p>

        <p>
            Les fichiers CARO reçoivent un nom logique et sont disponibles en format PDF et en format XML structuré.
        </p>

        <p>
            CARO est disponible pour les cartes de crédit Mastercard et VISA émises par les banques suivantes.
            Le nombre de banques prises en charge s'élargit progressivement :
        </p>

        <a href='https://www.codabox.com/fr/caro/' target='_blank'>https://www.codabox.com/fr/caro/ </a>

        <p>
            Via MyCodabox, vous pouvez commander CARO et suivre l'état d’avancement de vos demandes.
            Pour activer CARO, il doit y avoir au moins un mandat CODA actif pour l'entreprise (dossier).
        </p>

        <h3>Tarifs</h3>

        <h4 class='cb-service-title'>
            Frais administratifs
        </h4>

        <p>
            24 € par référence client (*) pour laquelle CARO a été activé
        </p>

        <h4 class='cb-service-title'>
            Frais récurrents
        </h4>

        <table class='table' v-if='isDirectCustomer'>
            <tbody>
                <tr>
                    <td>> 2 références clients CARO actives</td>
                    <td>7,73 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>1 - 2 références clients CARO actives</td>
                    <td>9,62 €/mois/référence client</td>
                </tr>
            </tbody>
        </table>
        <p v-if='isDirectCustomer'>
            Si vous gérez plusieurs entreprises sous un seul contrat Codabox, le nombre total de références clients CARO détermine le tarif des frais récurrents.
        </p>
        <p class='note' v-if='isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CARO reste actif jusqu'à ce que vous archiviez le service via MyCodabox, ou que le mandat soit arrêté.
        </p>
        <table class='table' v-if='!isDirectCustomer'>
            <tbody>
                <tr>
                    <td>+ 500 références client</td>
                    <td>3,72 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 241 et 500 références client</td>
                    <td>4,65 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 121 et 240 références client</td>
                    <td>5,14 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 61 et 120 références client</td>
                    <td>6,45 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 3 et 60 références client</td>
                    <td>7,73 €/mois/référence client</td>
                </tr>
                <tr>
                    <td>Entre 1 et 2 références client</td>
                    <td>9,62 €/mois/référence client</td>
                </tr>
            </tbody>
        </table>

        <p v-if='!isDirectCustomer'>
            Pour l’établissement du tarif correct, il est également tenu compte du nombre de services activés pour CODA et VOILA.
            Pour plus de détails veuillez consulter les Conditions Générales, via le lien ci-dessous.
        </p>

        <p class='note' v-if='!isDirectCustomer'>
            <span class='fa fa-info-circle'></span>
            CodaBox ne peut pas déterminer quand le service CODA, CARO ou VOILA n'est plus nécessaire pour un certain client, une carte de crédit ou un compte bancaire,
            par exemple lorsqu'il n'y a plus de transactions sur un compte ou le compte est clôturé.
            Le service reste actif (et fait partie du calcul des frais récurrents) jusqu'à ce que vous le
            <a href='https://faq.codabox.com/fr/support/solutions/articles/75000087558-comment-d%C3%A9sactiver-un-service-coda-soda-voila-caro-' target='_blank'>désactiviez vous-même via MyCodabox</a>.
        </p>

        <p>
            (*) identification des relevés de carte de crédit : certains produits bancaires permettent plusieurs cartes sur 1 relevé de carte de crédit (c.-à-d. référence client).
        </p>

        <h3>Conditions Générales</h3>
        <p v-if='isDirectCustomer'>
            <a href='/static/pdfs/202503 Contrat Codabox Business CODA-SODA-CARO_FR.pdf' target='_blank'>Contrat CodaBox Business</a>
        </p>
        <p v-else>
            <a href='/static/pdfs/MyCodabox.com 202501 CB-Fidu FR-Annex CARO (B4-C4-D).pdf' target='_blank'>Conditions générales du service</a>
        </p>
    </div>
</template>

<style lang='scss' scoped>
    h3.cb-service-main-title {
        margin-top: 10px;
    }
    h4.cb-service-title {
        text-decoration: underline;
    }
    .cb-service-info-highlight {
        font-style: italic;
        font-weight: bold;
    }
    p.note {
        font-style: italic;
    }
    p.note .fa-info-circle {
        font-size: 16px;
    }
    p, table {
        margin: 10px 0;
    }
    table {
        table-layout: fixed;
    }
    tbody {
        color: $primary-color-dark;
    }
</style>

<script>
export default {
    name: 'ListRow',
    props: {
        landmark: {
            type: Boolean,
            default: false,
        },
        background: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<template>
    <tr :class='{ background }' class='h-16 list-row'>
        <slot></slot>
    </tr>
    <tr v-show='landmark' class='landmark'>
        <td class='absolute bg-grey-250 w-full h-1 rounded-full'></td>
    </tr>
</template>

<style lang="postcss">

.list-row td {
    @apply px-5 h-12;
}

.landmark {
    position: relative;
}

.background {
    @apply bg-grey-50;
}
</style>

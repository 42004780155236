<script>
export default {
    name: 'CustomTitle',
    props: {
        level: {
            type: [Number, String],
            default: 1,
        },
        variant: {
            type: String,
            default: '',
        },
        styleOf: {
            type: Number,
        },
        content: {
            type: String,
            default: null,
        },
        noDefaultMargins: {
            type: Boolean,
            default: false,
        },
        leading: {
            type: String,
            default: 'snug',
        },
    },
};
</script>

<template>
    <component
        :is='`h${level}`'
        :class='`
            title
            h${styleOf ? styleOf : level}
            ${variant}
            ${noDefaultMargins ? "m-0 p-0" : ""}
            leading-${leading}
        `'
    >
        <slot></slot>
        <span v-if='content'>{{ $t(content) }}</span>
    </component>
</template>

<style lang="postcss" scoped>
.title {
    @apply text-grey-700;
}

.h1 {
  @apply text-6xl font-normal;
}

.h2 {
  @apply text-3xl font-normal;
}

.h3 {
  @apply text-4xl font-bold;
}

.h4 {
  @apply text-2xl;
}

.h5 {
  @apply text-2xl;
}

.h6 {
  @apply text-base uppercase;
}

.bold {
  @apply font-bold;
}

.big {
  font-size: 3em;
}

.white {
  @apply text-white;
}

.orange {
  @apply text-orange-100;
}

.light {
  @apply font-light;
}

@screen md {
    .h1 {
        @apply text-6xl;
    }

    .h2 {
        @apply text-4xl;
    }

    .h3 {
        @apply text-3xl;
    }

    .big {
        font-size: 5em;
    }
}
</style>

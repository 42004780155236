<script>
    import ListView from '@/components/ListView.js';
    import BankAccount from '@/components/BankAccount.vue';
    import CodaState from '@/components/CodaState.vue';
    import Twikey from '@/components/TwikeyNolink.vue';
    import ContentBox from '@/components/ContentBox.vue';
    import { dateFormat } from '@/filters';
    import SearchInput from '@/components/SearchInput';
    import Dropdown from '@/components/DropdownV2';
    import DropdownGroup from '@/components/DropdownGroup';
    import DropdownItem from '@/components/DropdownItem';
    import CustomTitle from '@/components/Title';

    export default {
        name: 'ResellerCodaSearch',
        mixins: [ListView],
        props: {
            currentReseller: {
                type: Object,
            },
        },
        components: {
            BankAccount,
            CodaState,
            Twikey,
            ContentBox,
            SearchInput,
            Dropdown,
            DropdownGroup,
            DropdownItem,
            CustomTitle,
        },
        data () {
            const data = this.listViewData({
                apiModel: 'bff/api/search/reseller/coda-mandates',
                defaultSortKey: 'fiduciary__name',
                defaultFilters: { client__state: 'active' },
            });

            // configure environment filter
            this.configureSelectFilter(data, 'environment', this.getEnvFilterOptions(), this.getUrlEnvironment());

            // configure organization filter
            const orgFilterOptions = [{
                value: 'all-organizations',
                label: 'opt-all-organizations',
                filter: null,
            }];

            for (const org of this.currentReseller.organizations) {
                orgFilterOptions.push({
                    value: org.id,
                    label: org.enterpriseName,
                    filter: { organizationIds: [org.id] },
                });
            }

            this.configureSelectFilter(data, 'organization', orgFilterOptions);

            // configure state filter
            this.configureSelectFilter(data, 'state', [
                {
                    value: 'allstates',
                    label: 'opt-mandate-all',
                    filter: null,
                }, {
                    value: 'pre-treatment',
                    label: 'lbl-coda-pre-treatment',
                    filter: { state: 'pre-treatment' },
                }, {
                    value: 'prepared',
                    label: 'lbl-coda-prepared',
                    filter: { state: 'prepared' },
                }, {
                    value: 'sent-client',
                    label: 'lbl-coda-sent-client',
                    filter: { state: 'sent-client' },
                }, {
                    value: 'available-online',
                    label: 'lbl-coda-available-online',
                    filter: { state: 'available-online' },
                }, {
                    value: 'signed',
                    label: 'lbl-coda-signed',
                    filter: { state: 'signed' },
                }, {
                    value: 'bank-procedure',
                    label: 'lbl-coda-bank-procedure',
                    filter: { state: 'bank-procedure' },
                }, {
                    value: 'active',
                    label: 'lbl-coda-active',
                    filter: { state: 'active' },
                }, {
                    value: 'archived',
                    label: 'lbl-coda-archived',
                    filter: { state: 'archived' },
                }, {
                    value: 'problem',
                    label: 'lbl-coda-problem',
                    filter: { state: 'problem' },
                },
            ]);

            return data;
        },
        watch: {
            '$route.query.fiduciaryId': function () {
                this.setSelectFilterValue('environment', this.getUrlEnvironment());
            },
            'list.filters.organization.selected': function (newVal) {
                // in environment dropdown filter; only display environments belonging to the organizations selected in organizations dropdown filter
                const envFilter = this.list.filters.environment;
                envFilter.options = this.getEnvFilterOptions(newVal);
                envFilter.selected = 'all-environments';
            },
        },
        mounted () {
            this.watchFilters();
            this.search();
        },
        methods: {
            dateFormat,
            dynamicSearchParams () {
                return { resellerIds: [this.currentReseller.id] };
            },
            getUrlEnvironment () {
                return this.$route.query.fiduciaryId || 'all-environments';
            },
            getEnvFilterOptions (selectedOrganizationId = 'all-organizations') {
                const allEnvironmentsOption = {
                    value: 'all-environments',
                    label: 'opt-all-environments',
                    filter: null,
                };

                const mapEnvironment = env => ({
                    value: env.id,
                    label: env.name,
                    filter: { fiduciaryIds: [env.id] },
                });

                const environments = selectedOrganizationId === 'all-organizations'
                    ? this.currentReseller.fiduciaries
                    : this.currentReseller.fiduciaries.filter(env => env.accountantId === selectedOrganizationId);

                return [
                    allEnvironmentsOption,
                    ...environments.map(mapEnvironment),
                ];
            },
        },
    };
</script>

<template>
    <div>
        <div class='cb-fidu-title'>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('h-coda-mandates') }}
            </CustomTitle>
        </div>

        <content-box>
            <div class='flex'>
                <SearchInput v-model='list.textsearch' class='mr-3' />

                <Dropdown
                    v-model='list.filters.organization.selected'
                    class='z-50 mr-3'
                    default-value='all-organizations'
                    direction='right'
                >
                    <DropdownGroup scrollable>
                        <DropdownItem v-for='option in list.filters.organization.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                            {{ $t(option.label) }}
                        </DropdownItem>
                    </DropdownGroup>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.environment.selected'
                    class='z-50 mr-3'
                    default-value='all-environments'
                    direction='right'
                >
                    <DropdownGroup scrollable>
                        <DropdownItem v-for='option in list.filters.environment.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                            {{ $t(option.label) }}
                        </DropdownItem>
                    </DropdownGroup>
                </Dropdown>

                <Dropdown
                    v-model='list.filters.state.selected'
                    class='z-50'
                    default-value='allstates'
                    direction='right'
                >
                    <DropdownGroup scrollable>
                        <DropdownItem v-for='option in list.filters.state.options' :id='option.value' :name='$t(option.label)' :key='option.value'>
                            {{ $t(option.label) }}
                        </DropdownItem>
                    </DropdownGroup>
                </Dropdown>
            </div>
            <pagination
                :list='list'
                :pagination='pagination'
                :ctrls='getControls()'
                size='small'
                class='my-6'
            />

            <div :class='{"table-responsive": $store.state.gui === "mobile"}'>
                <table class='table cb-searchresults'>
                    <colgroup>
                        <collumn class='cb-col-md' :sorting='sorting' skey='fiduciary__organization_name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='fiduciary__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__client_code' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='client__name' />
                        <col class='cb-col-bg'>
                        <collumn class='cb-col-md' :sorting='sorting' skey='bank__name' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state_modifed_at' />
                        <collumn class='cb-col-md' :sorting='sorting' skey='state' />
                        <col class='cb-col-xs'>
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th>
                                <span>{{ $t('th-organization-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='fiduciary__organization_name' />
                            </th>
                            <th>
                                <span>{{ $t('th-environment-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='fiduciary__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-code') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__client_code' />
                            </th>
                            <th>
                                <span>{{ $t('th-client-name') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='client__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-accounts') }}</span>
                            </th>
                            <th>
                                <span>{{ $t('th-bank') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='bank__name' />
                            </th>
                            <th>
                                <span>{{ $t('th-state-date') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state_modified_at' />
                            </th>
                            <th>
                                <span>{{ $t('th-state') }}</span>
                                <sorttoggle :ctrls='getControls()' :sorting='sorting' skey='state' />
                            </th>
                            <th>
                                <span>{{ $t('th-e-signature') }}</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for='record in list.records'>
                            <td>{{ record.fiduciary.organization_name }}</td>
                            <td>{{ record.fiduciary.name }}</td>
                            <td>{{ record.client.client_code }}</td>
                            <td>{{ record.client.name }}</td>
                            <td>
                                <BankAccount v-for='ba in record.bank_accounts' :key='ba.iban' :bank-account='ba' />
                            </td>
                            <td>{{ record.bank.name }}</td>
                            <td>{{ dateFormat(record.state_modified_at) }}</td>
                            <td>
                                <CodaState :mandate='record' />
                            </td>
                            <td>
                                <Twikey :url='record.twikey_url' />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <listloader :list='list' />

            <div class='text-center' v-if='!list.loading'>
                <pagination
                    :list='list'
                    :pagination='pagination'
                    :ctrls='getControls()'
                    scroll='scrollTop'
                    no-margin
                />
            </div>
        </content-box>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { PASSWORD_RULES } from '@/constants/common';
import validate from '@/validate';
import Icon from '@/components/Icon';

const props = defineProps({
    password: {
        type: String,
        default: '',
    },
    centered: {
        type: Boolean,
        default: false,
    },
});

const safePassword = computed(() => props.password || '');

function checkPasswordRule (rgx, password) {
    return validate.checkPasswordRule(rgx, password || '');
}
</script>

<template>
    <div class='mt-3' :class='centered ? "text-center" : ""'>
        <ul class='list-none pl-0 text-sm font-medium' :class='centered ? "mx-auto" : ""'>
            <li
                class='list-none mb-1 last:mb-0'
                v-for='rule in PASSWORD_RULES'
                :key='rule.label'
            >
                <span
                    v-if='safePassword.length > 0 && checkPasswordRule(rule.regex, safePassword)'
                    class='flex items-center text-green-500'
                    :class='centered ? "justify-center" : ""'
                >
                    <Icon name='Check' class='mr-2 flex-shrink-0' width='w-6' />
                    {{ $t(rule.label) }}
                </span>
                <span v-else class='text-grey-300 flex items-center' :class='centered ? "justify-center" : ""'>
                    <Icon name='ArrowRight' class='mr-1 flex-shrink-0' width='w-6' />
                    {{ $t(rule.label) }}
                </span>
            </li>
        </ul>
    </div>
</template>

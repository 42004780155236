<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
import { defineProps, computed, ref, onMounted } from 'vue';
import * as solidIcons from '@heroicons/vue/16/solid';
import * as outlineIcons from '@heroicons/vue/24/outline';

const props = defineProps({
    name: {
        type: String,
        required: true,
    },
    family: {
        type: String,
        default: 'solid',
        validator: (value) => ['solid', 'outline'].includes(value),
    },
    width: {
        type: String,
        default: 'w-7',
    },
    featured: Boolean,
    featuredBgColor: {
        // this comment is important, it makes tailwind include thoses classes in the final build
        // bg-green-100, bg-green-200, bg-blue-200, bg-yellow-200, bg-red-200...
        type: String,
        default: 'bg-blue-200',
    },
    pulse: Boolean,
});

const component = computed(() => {
    switch (props.family) {
        case 'outline':
            return outlineIcons[`${props.name}Icon`];
        default:
            return solidIcons[`${props.name}Icon`];
    }
});

const pulseActive = ref(props.pulse);

onMounted(() => {
    if (props.pulse) {
        setTimeout(() => {
            pulseActive.value = false;
        }, 3000);
    }
});

</script>

<template>
    <div class='icon' :class='{ featured: props.featured }'>
        <component :is='component' :class='`${props.width}`' class='icon__svg' />
        <div v-if='props.featured' :class='`absolute h-10 w-10 rounded-full ${props.featuredBgColor} z-2`'></div>
        <div v-if='props.featured' :class='`absolute h-14 w-14 rounded-full ${props.featuredBgColor} opacity-30 z-1`'></div>
        <div v-if='props.featured' :class='`absolute h-10 w-10 rounded-full ${props.featuredBgColor} z-1 ${pulseActive ? "animate-ping" : ""}`'></div>
    </div>
</template>

<style lang='postcss' scoped>
.featured {
    @apply relative h-14 w-14 flex items-center justify-center;

    .icon__svg {
        z-index: 3;
    }
}
</style>

<script>
    import { simpleLocale } from '@/i18n.js';
    import PrismicPopup from '../components/PrismicPopup.vue';
    import Breadcrumb from '@/components/Breadcrumb.vue';
    import CustomTitle from '@/components/Title';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import Sidenav from '@/components/sidenav/Sidenav.vue';
    import SidenavItem from '@/components/sidenav/SidenavItem.vue';
    import SidenavGroup from '@/components/sidenav/SidenavGroup.vue';
    import SidenavCurrentEnv from '@/components/sidenav/SidenavCurrentEnv.vue';
    import bus from '@/bus.js';
    import ContractMigrationPopup from '@/organization/ContractMigrationPopup';

    export default {
        name: 'FiduciaryView',
        props: {
            organization: Object,
        },
        components: {
            PrismicPopup,
            Breadcrumb,
            CustomTitle,
            FriendlyButton,
            Sidenav,
            SidenavItem,
            SidenavGroup,
            SidenavCurrentEnv,
            ContractMigrationPopup,
        },
        computed: {
            eboxUrl () {
                const locale = simpleLocale(this.$i18n.locale);
                return {
                    en: 'https://myebox.be/nl',
                    nl: 'https://myebox.be/nl',
                    fr: 'https://myebox.be/fr',
                }[locale];
            },
            featuresUrl () {
                const locale = simpleLocale(this.$i18n.locale);
                return {
                    en: 'https://faq.codabox.com/en/support/solutions/articles/75000031453-what-s-new-overview',
                    nl: 'https://faq.codabox.com/nl/support/solutions/articles/75000031453-wat-is-er-nieuw-overzicht',
                    fr: 'https://faq.codabox.com/fr/support/solutions/articles/75000031453-apercu-des-am%C3%A9liorations',
                }[locale];
            },
            currentEnvironment: {
                get () {
                    const currentEnvironment = this.organization.fiduciaries.find((fidu) => {
                        return fidu.id === this.$route.params.environmentId;
                    });
                    return currentEnvironment || null;
                },
                deep: true,
            },
            hasCodaOnlyContract () {
                return this.organization.hasCodaOnlyContract;
            },
            isDirectCustomer () {
                return this.organization.isDirectCustomer;
            },
            organizationType () {
                return this.isDirectCustomer ? 'directCustomer' : 'accountant';
            },
        },
        watch: {
            '$route.params.organizationId': function (newValue, _) {
                if (!this.organization.hasCodaOnlyContract) {
                    bus.emit('trigger-tutorial-initial', { organizationType: this.organizationType });
                }
            },
            '$route.params.environmentId': function (newValue, _) {
                // save the last used environment in local storage
                localStorage.selectedEnvironmentId = newValue;
            },
        },
        emits: ['update:organization'],
        created () {
            if (!this.organization.hasCodaOnlyContract) {
                bus.emit('trigger-tutorial-initial', { organizationType: this.organizationType });
            }
        },
        methods: {
            // this method is setting the current fiduciary using a complete object
            // typically used to bubble up fiduciary updates from child components
            setCurrentFiduciary (fiduciary) {
                for (const fidu of this.organization.fiduciaries) {
                    if (fidu.id === this.currentEnvironment.id) {
                        Object.assign(fidu, { ...fidu, ...fiduciary });
                    }
                }
            },
            contractMigrated () {
                bus.emit('trigger-tutorial-initial', { organizationType: this.organizationType });
                this.$emit('update:organization');
            },
        },
    };
</script>

<template>
    <div class='h-full'>
        <template v-if='currentEnvironment'>
            <div class='h-full lg:flex'>
                <PrismicPopup :space='organizationType' />

                <Sidenav collapsable>
                    <!-- Current Environment -->
                    <SidenavGroup>
                        <SidenavCurrentEnv :name='currentEnvironment.name' />
                    </SidenavGroup>

                    <SidenavGroup background>
                        <SidenavItem
                            :to='{ name: "dashboard" }'
                            :text='$t("nav-dashboard")'
                            icon='home'
                            active-route='dashboard'
                        />
                    </SidenavGroup>

                    <!-- Group for Clients -->
                    <SidenavGroup :title='$t("nav-clients")' collapsable background class='sidenav-companies'>
                        <SidenavItem
                            :to='{ name: "fidu-client-search" }'
                            :text='$t("nav-fidu-client-search")'
                            :active-route='"client"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-client-regular-transfer-list" }'
                            :text='$t("nav-fidu-client-transfer-lists")'
                            :active-route='"client-transfer"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-client-new" }'
                            :text='$t("btn-new-client")'
                            icon='plus'
                            :active-route='"client-new"'
                        />
                    </SidenavGroup>

                    <!-- Group for Documents -->
                    <SidenavGroup :title='$t("nav-documents")' collapsable background class='sidenav-products'>
                        <SidenavItem
                            :to='{ name: "fidu-coda-search" }'
                            :text='$t("nav-fidu-coda-search")'
                            :active-route='"coda"'
                        />
                        <SidenavItem
                            v-if='!hasCodaOnlyContract'
                            :to='{ name: "fidu-soda-search" }'
                            :text='$t("nav-fidu-soda-search")'
                            :active-route='"soda"'
                        />
                        <SidenavItem
                            v-if='!hasCodaOnlyContract && !isDirectCustomer'
                            :to='{ name: "fidu-purchase-search" }'
                            :text='$t("nav-fidu-purchase-search")'
                            :active-route='"purchases"'
                        />
                        <SidenavItem
                            v-if='!hasCodaOnlyContract'
                            :to='{ name: "fidu-ccs-clients-list" }'
                            :text='$t("nav-fidu-ccs-clients-list")'
                            :active-route='"ccs-clients-list"'
                        />
                    </SidenavGroup>

                    <!-- Group for Settings -->
                    <SidenavGroup :title='$t("nav-environment-settings")' collapsable background class='sidenav-info'>
                        <SidenavItem
                            :to='{ name: "fidu-services-root" }'
                            :text='$t("nav-fidu-services-root")'
                            :active-route='"services"'
                        />
                        <SidenavItem
                            :to='{ name: "fidu-info" }'
                            :text='$t("nav-fidu-info")'
                            :active-route='"info"'
                        />
                    </SidenavGroup>

                    <!-- Group for Links -->
                    <SidenavGroup :title='$t("nav-links")' collapsable background>
                        <SidenavItem
                            :external-link='eboxUrl'
                            :text='$t("nav-ebox")'
                        />
                        <SidenavItem
                            :external-link='featuresUrl'
                            :text='$t("nav-whats-new")'
                        />
                        <SidenavItem
                            external-link='https://status.codabox.com'
                            :text='$t("nav-status-page")'
                        />
                    </SidenavGroup>
                </Sidenav>

                <div class='pl-6 lg:pl-0 pr-6 lg:h-full w-full'>
                    <Breadcrumb :more='{ organization: organization.enterpriseName, environment: currentEnvironment.name }' class='mt-8' />
                    <router-view
                        :accountant='organization'
                        :current-environment='currentEnvironment'
                        @fiduciary-updated='setCurrentFiduciary'
                    />
                </div>
            </div>
            <ContractMigrationPopup
                :organization='organization'
                :environment-id='currentEnvironment.id'
                :user='$store.state.user'
                @migrated='contractMigrated'
            />
        </template>
        <template v-else>
            <div class='container flex flex-col items-center justify-center h-full'>
                <CustomTitle class='mb-6 font-black text-center'>
                    {{ $t('ttl-err-no-access') }}
                </CustomTitle>
                <p class='text-center text-xl max-w-4xl mx-auto'>
                    {{ $t('p-err-no-access') }}
                </p>
                <FriendlyButton
                    label='btn-err-no-access'
                    symbol='home'
                    square
                    :action='() => $router.push("/")'
                />
            </div>
        </template>
    </div>
</template>

<script setup>
import { onMounted, ref, watch, computed } from 'vue';
import { hashString } from '@/utils.js';
import { datadogRum } from '@datadog/browser-rum';
import Wizard from '@/components/Wizard';
import WizardStep from '@/components/WizardStep';
import WizardButtons from '@/components/WizardButtons';
import WizardNextButton from '@/components/WizardNextButton';
import WizardPreviousButton from '@/components/WizardPreviousButton';
import WizardStepContent from '@/components/WizardStepContent';
import { Form } from 'vee-validate';
import Popup from '@/clientcomponents/Popup.vue';
import Tooltip from '@/components/Tooltip.vue';
import utils from '../utils.js';
import Icon from '@/components/Icon';
import CustomTitle from '@/components/Title';
import DropdownItem from '@/components/DropdownItem.vue';
import Dropdown from '@/components/DropdownV2.vue';
import DropdownGroup from '@/components/DropdownGroup';
import FormCheckbox from '@/components/FormCheckbox.vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import notify from '@/notify';
import { simpleLocale, t } from '@/i18n';
import { gql } from '@apollo/client/core';
import FormInput from '@/components/FormInput.vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import validate from '@/validate';
import { useRouter } from 'vue-router';
import Loader from '@/loader.js';

const props = defineProps({
    organization: {
        type: Object,
        required: true,
    },
    isShown: {
        type: Boolean,
        required: true,
    },
    close: {
        type: Function,
        required: true,
    },
});

const store = useStore();
const router = useRouter();
const { locale } = useI18n();
const loadingSoftware = ref(true);
const softwareList = ref([]);
const resellerList = ref([]);

const emits = defineEmits(['update:organization']);

const softwareSelection = ref('');
const resellerSelection = ref('');
const softwareCustomerReference = ref('');
const exactOnlineMailbox = ref('');
const customSoftware = ref('');
const customReseller = ref('');
const customMadeSoftwareId = ref(null);
const enDirectResellerId = ref(null);
const representativeName = ref('');
const representativeEmailAddress = ref('');
const representativeFunction = ref('');
const representativePhoneNumber = ref('');

// Check if we have a reseller referral in the store
const hasResellerReferral = computed(() => !!(store.state.user.tempResellerReferral && store.state.user.tempEnforcedSoftware));

// Check if we have a software referral in the store
const hasSoftwareReferral = computed(() => !!store.state.user.tempEnforcedSoftware);

async function getSoftwareList () {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(gql`
          query softwareList {
            softwareList {
              results {
                id,
                name,
                slug,
                requiresCustomerReference
              }
            }
          }
        `);
        onResult(() => {
            softwareList.value = result.value.softwareList.results;
            customMadeSoftwareId.value = softwareList.value.find(s => s.slug === 'custom-made')?.id;
            loadingSoftware.value = false;
            resolve();
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

async function getResellerBySoftware (softwareId) {
    return new Promise((resolve, reject) => {
        const { result, onResult, onError } = useQuery(gql`
          query resellerBySoftware($softwareId: String) {
            resellerBySoftware(softwareId:$softwareId) {
              id,
              name,
              slug,
              type
            }
          }
        `, {
            softwareId,
        });

        onResult(() => {
            resolve(result.value.resellerBySoftware);
        });

        onError((error) => {
            notify.error(t('err-unknown'));
            reject(error);
        });
    });
}

// Function to update reseller list and set direct reseller ID
async function updateResellerList (softwareId) {
    resellerList.value = await getResellerBySoftware(softwareId);
    enDirectResellerId.value = resellerList.value.find(r => r.slug === 'en-direct')?.id;
    return resellerList.value;
}

onMounted(async () => {
    await getSoftwareList();

    if (hasSoftwareReferral.value) {
        const enforcedSoftware = store.state.user.tempEnforcedSoftware;
        if (softwareList.value.some(software => software.id === enforcedSoftware)) {
            softwareSelection.value = enforcedSoftware;
        }
    }

    if (hasResellerReferral.value) {
        await updateResellerList(softwareSelection.value);
    }
});

async function submitSoftwareStep (nextStep, values) {
    const selectedSoftware = softwareList.value.find(s => s.id === softwareSelection.value);
    await updateResellerList(selectedSoftware.id);

    if (isExactOnline() && props.organization.isDirectCustomer) {
        exactOnlineMailbox.value = values.exactMailbox;
    } else if (requiresReference()) {
        softwareCustomerReference.value = values.customerReference;
    }

    if (selectedSoftware.id !== customMadeSoftwareId.value) {
        customSoftware.value = ''; // make sure this is empty when not visible, we'll be using it for the name of the environment
    }
    if (hasResellerReferral.value && resellerList.value.some(reseller => reseller.id === store.state.user.tempResellerReferral.id)) {
        resellerSelection.value = store.state.user.tempResellerReferral.id;
    } else {
        resellerSelection.value = '';
    }
    customReseller.value = '';
    nextStep();
}

function requiresReference () {
    if (!softwareSelection.value) return false;
    return softwareList.value.find(s => s.id === softwareSelection.value)?.requiresCustomerReference;
}

function isExactOnline () {
    if (!softwareSelection.value) return false;
    return softwareList.value.find(s => s.id === softwareSelection.value)?.slug === 'exactonline';
}

function submitLegalRepresentativeStep (values, nextStep) {
    representativeName.value = values.representativeName;
    representativeEmailAddress.value = values.representativeEmailAddress;
    representativeFunction.value = values.representativeFunction;
    representativePhoneNumber.value = values.representativePhoneNumber;
    nextStep();
}

async function addNewEnvironment (values) {
    if (!values.termsAndConditions) {
        return;
    }

    Loader.start();

    const trackingData = {
        hashedEnterpriseNumber: hashString(props.organization.enterpriseNumber),
        isDirectCustomer: props.organization.isDirectCustomer,
    };

    // if there is a reseller referral, add the data to the tracking data
    if (hasResellerReferral.value) {
        trackingData.fromResellerReferral = true;
        trackingData.hashedResellerId = hashString(store.state.user.tempResellerReferral.id);
        trackingData.softwareId = store.state.user.tempEnforcedSoftware;
    }

    datadogRum.addAction('environment_creation_started', trackingData);

    try {
        const { mutate: addNewEnvMutation } = useMutation(gql`
            mutation AddNewEnvironment($input: AddNewEnvironmentInput) {
              addNewEnvironment(input: $input) {
                errors {
                  code
                  detail
                  source {
                    pointer
                  }
                }
                environment {
                  id
                }
              }
            }
        `);

        // Create environment
        const res = (await addNewEnvMutation({
            input: {
                accountantId: props.organization.id,
                resellerId: resellerSelection.value,
                softwareId: softwareSelection.value,
                name: customSoftware.value || softwareList.value.find(s => s.id === softwareSelection.value).name,
                language: simpleLocale(locale.value),
                softwareCustomerReference: softwareCustomerReference.value,
                representativeName: representativeName.value,
                representativeEmailAddress: representativeEmailAddress.value,
                representativeFunction: representativeFunction.value,
                representativePhoneNumber: representativePhoneNumber.value,
            },
        }));

        // TODO: we don't check error from addNewEnvMutatio? raise an sentry error ?

        const environmentId = res.data.addNewEnvironment.environment.id;
        if (props.organization.isDirectCustomer) {
            // Create company for the environment (bingo-client) for direct customers
            await createCompany(props.organization, store.state.user.email, environmentId, simpleLocale(locale.value));
        }

        // Update the "contractSignedDate" on organization because the user accepted the T&C at environment creation
        await updateContractSignedDateOnOrganization(props.organization);

        // Send signup email
        const selectedSoftware = softwareList.value.find(s => s.id === softwareSelection.value);
        const selectedReseller = resellerList.value.find(r => r.id === resellerSelection.value);

        // We need to pass the organization updated fields to the email functions
        // otherwise we'll be using the old values from the organization object, which sometimes are even empty
        // resulting in an error on the front
        const organizationWithUpdatedFields = {
            ...props.organization,
            representativeName: representativeName.value,
            representativeEmailAddress: representativeEmailAddress.value,
            representativeFunction: representativeFunction.value,
            representativePhoneNumber: representativePhoneNumber.value,
        };
        if (props.organization.fiduciaries.length === 0) {
            await sendFirstEnvironmentEmail(
                organizationWithUpdatedFields,
                selectedReseller,
                selectedSoftware,
                softwareCustomerReference.value,
                exactOnlineMailbox.value,
                customReseller.value,
                customSoftware.value,
            );
        } else {
            await sendNewEnvironmentEmail(
                organizationWithUpdatedFields,
                selectedReseller,
                selectedSoftware,
                softwareCustomerReference.value,
                customReseller.value,
                customSoftware.value,
            );
        }

        // Submit datadogRum action
        const successTrackingData = {
            ...trackingData,
            hashedEnvironmentId: hashString(environmentId),
        };

        datadogRum.addAction('environment_creation_completed_success', successTrackingData);

        emits('update:organization');
        watch(() => props.organization, (newOrganization, _) => {
            props.close();
            if (newOrganization) {
                router.push({ name: 'dashboard', params: { environmentId } });
            }
        }, { deep: true });

        // Clean up input fields
        softwareSelection.value = '';
        resellerSelection.value = '';
        softwareCustomerReference.value = '';
        exactOnlineMailbox.value = '';
        customSoftware.value = '';
        customReseller.value = '';
        representativeName.value = '';
        representativeEmailAddress.value = '';
        representativeFunction.value = '';
        representativePhoneNumber.value = '';
    } catch (errors) {
        datadogRum.addAction('environment_creation_completed_error', {
            ...trackingData,
            errors,
        });

        await validate.notifyErrors(errors);
    }
    Loader.stop();
}

async function createCompany (organization, contactEmail, environmentId, language) {
    try {
        const newClientInput = {
            address: organization.address,
            address2: organization.address2,
            city: organization.city,
            clientCode: organization.enterpriseNumber,
            contactEmail,
            enterpriseName: organization.enterpriseName,
            enterpriseNumber: organization.enterpriseNumber,
            fiduciaryId: environmentId,
            hasBelgianVatNumber: true,
            language,
            representativeFunction: organization.representativeFunction,
            representativeName: organization.representativeName,
            sendCodaAndSodaByMail: false,
            zip: organization.zip,
            exactEmail: exactOnlineMailbox.value || null,
        };

        const { mutate: newClientMutation } = useMutation(gql`
            mutation newClient($input: NewClientInput!) {
              newClient(input: $input) {
                data {
                  id
                  enterpriseNumber
                  clientCode
                }
                errors {
                  code
                  detail
                  source {
                        pointer
                    }
                }
              }
            }
        `);

        const resNewClientMutation = (await newClientMutation({
            input: newClientInput,
        })).data.newClient;

        if (resNewClientMutation.errors) {
            if (
                resNewClientMutation.errors.some(item => {
                    return (
                        item.source.pointer === '/data/enterpriseNumber' &&
                        item.detail === 'Conflict with client of another accountant.'
                    );
                })
            ) {
                const { mutate: createClientTransferMutation } = useMutation(gql`
                    mutation CreateClientTransferRequest($input: ClientTransferRequestCreateInput!) {
                        createClientTransferRequest(input: $input) {
                            errors { code, detail, source { pointer } }
                        }
                    }
                `);

                try {
                    const resCreateClientTransferMutation = (await createClientTransferMutation({
                        input: {
                            targetFiduciaryId: newClientInput.fiduciaryId,
                            enterpriseName: newClientInput.enterpriseName,
                            enterpriseNumber: newClientInput.enterpriseNumber,
                            hasBelgianVatNumber: true,
                            representativeName: newClientInput.representativeName,
                            representativeFunction: newClientInput.representativeFunction,
                            address: newClientInput.address,
                            address2: newClientInput.address2,
                            zip: newClientInput.zip,
                            city: newClientInput.city,
                            clientCode: newClientInput.clientCode,
                            language: newClientInput.language,
                            contactName: newClientInput.representativeName,
                            contactEmail: newClientInput.contactEmail,
                            exactEmail: newClientInput.exactEmail,
                            codaDeliveryStartDate: null,
                            sendCodaAndSodaByMail: false,
                            orderVoila: false,
                            voilaDeliveryEmail: null,
                            bankAccounts: [],
                            socialOffices: [],
                            createSupportTicket: true,
                        },
                    })).data.createClientTransferRequest;

                    if (resCreateClientTransferMutation.errors) {
                        // TODO: raise sentry error to be notified?
                        notify.error(t('err-unknown'));
                    } else {
                        notify.infoWithoutTimeout(t('add-new-environment.client-transfer-info'));
                    }
                } catch (e) {
                    // TODO: raise sentry error to be notified?
                    notify.error(t('err-unknown'));
                }
            } else {
                notify.error(t('err-unknown'));
            }
        }
    } catch (e) {
        // TODO: raise sentry error to be notified?
        notify.error(t('err-unknown'));
    }
}

async function sendFirstEnvironmentEmail (organization, reseller, software, customerReference, exactMailbox, customResellerName = null, customSoftwareName = null) {
    const { mutate: sendFirstEnvEmailMutation } = useMutation(gql`
        mutation SendOrganizationFirstEnvironmentEmail($input: sendOrganizationFirstEnvironmentEmailInput!) {
            sendOrganizationFirstEnvironmentEmail(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);
    let invoiceMethod;
    if (software.slug === 'bob') {
        invoiceMethod = 'sagebob';
    } else {
        invoiceMethod = reseller.type;
    }
    invoiceMethod ||= 'direct';  // reseller.type can be null
    try {
        const res = (await sendFirstEnvEmailMutation({
            input: {
                legalRepresentativeName: organization.representativeName,
                legalRepresentativeEmail: organization.representativeEmailAddress,
                legalRepresentativeFunction: organization.representativeFunction,
                legalRepresentativePhoneNumber: representativePhoneNumber.value,
                enterpriseNumber: organization.enterpriseNumber,
                organizationName: organization.enterpriseName,
                organizationAddress: organization.address,
                organizationAddress2: organization.address2,
                organizationZip: organization.zip,
                organizationCity: organization.city,
                softwareName: customSoftwareName ? `${t('add-new-environment.email.custom-software-prefix')} - ${customSoftwareName}` : software.name,
                resellerName: customResellerName ? `${t('add-new-environment.email.custom-software-prefix')} - ${customResellerName}` : reseller.name,
                softwareRequiresCustomerReference: requiresReference(),
                softwareCustomerReference: customerReference,
                softwareIsExactOnline: isExactOnline(),
                exactOnlineMailbox: exactMailbox,
                invoiceEmail: organization.invoicingEmailAddress,
                invoiceMethod,
                language: simpleLocale(locale.value),
                organizationIsDirectCustomer: organization.isDirectCustomer,
            },
        })).data.sendOrganizationFirstEnvironmentEmail;

        if (res.errors) {
            notify.error(t('err-unknown'));
        }
    } catch (error) {
        notify.error(t('err-unknown'));
    }
}

async function sendNewEnvironmentEmail (organization, reseller, software, customerReference, customResellerName = null, customSoftwareName = null) {
    const { mutate: sendNewEnvEmailMutation } = useMutation(gql`
        mutation SendOrganizationNewEnvironmentEmail($input: sendOrganizationNewEnvironmentEmailInput!) {
            sendOrganizationNewEnvironmentEmail(input: $input) {
                errors {
                    code
                    detail
                    source {
                        pointer
                    }
                }
            }
        }
    `);
    let invoiceMethod;
    if (software.slug === 'bob') {
        invoiceMethod = 'sagebob';
    } else {
        invoiceMethod = reseller.type;
    }
    invoiceMethod ||= 'direct';  // reseller.type can be null
    try {
        const res = (await sendNewEnvEmailMutation({
            input: {
                legalRepresentativeName: organization.representativeName,
                legalRepresentativeEmail: organization.representativeEmailAddress,
                legalRepresentativeFunction: organization.representativeFunction,
                legalRepresentativePhoneNumber: representativePhoneNumber.value,
                enterpriseNumber: organization.enterpriseNumber,
                organizationName: organization.enterpriseName,
                organizationAddress: organization.address,
                organizationAddress2: organization.address2,
                organizationZip: organization.zip,
                organizationCity: organization.city,
                softwareName: customSoftwareName ? `${t('add-new-environment.email.custom-software-prefix')} - ${customSoftwareName}` : software.name,
                resellerName: customResellerName ? `${t('add-new-environment.email.custom-software-prefix')} - ${customResellerName}` : reseller.name,
                softwareRequiresCustomerReference: requiresReference(),
                softwareCustomerReference: customerReference,
                invoiceEmail: organization.invoicingEmailAddress,
                invoiceMethod,
                language: simpleLocale(locale.value),
            },
        })).data.sendOrganizationNewEnvironmentEmail;

        if (res.errors) {
            notify.error(t('err-unknown'));
        }
    } catch (error) {
        notify.error(t('err-unknown'));
    }
}
async function updateContractSignedDateOnOrganization (organization) {
    try {
        const { mutate: updateOrganizationMutation } = useMutation(gql`
            mutation UpdateOrganization($organizationId: String, $input: UpdateOrganizationInput) {
                updateOrganization(organizationId: $organizationId, input: $input) {
                    errors { code, detail, source { pointer } }
                }
            }
        `);

        await updateOrganizationMutation(
            {
                organizationId: organization.id,
                input: {
                    contractSignedDate: utils.date2strYYYYMMDD(new Date()),
                },
            },
        );
    } catch (error) {
        notify.error(t('err-unknown'));
    }
}

function softwareStepValid (values) {
    if (isExactOnline() && values.exactMailbox) {
        return utils.validateExactEmail(values.exactMailbox);
    }
    return !!softwareSelection.value;
}

const termsConditionsUrl = computed(() => {
    return props.organization.isDirectCustomer ? t('add-new-environment.steps.terms-and-conditions.url.direct-customer') : t('add-new-environment.steps.terms-and-conditions.url.accountant');
});

watch(() => props.isShown, (isShown) => {
    if (isShown) {
        // we consider this as the start of the environment creation flow
        datadogRum.addAction('environment_creation_started', {
            hashedEnterpriseNumber: hashString(props.organization.enterpriseNumber),
            isDirectCustomer: props.organization.isDirectCustomer,
        });
    }
});
</script>

<template>
    <Popup :show='props.isShown' :close='props.close'>
        <template #header>
            {{ $t('add-new-environment.popup.title') }}
        </template>
        <Wizard v-slot='{ previousStep, nextStep }' full-width>
            <WizardStep title='add-new-environment.steps.software.title' icon='Cube' class='flex flex-col'>
                <Form tag='div' v-slot='{ values, handleSubmit }' class='flex flex-col grow'>
                    <WizardStepContent bordered class='h-full flex flex-col grow'>
                        <div class='flex flex-row items-center'>
                            <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                                {{ $t('add-new-environment.steps.software.content') }}
                            </CustomTitle>
                            <Tooltip class='mt-0' right>
                                <template #trigger>
                                    <Icon name='InformationCircle' family='outline' class='ml-2 text-grey-300' width='w-6' />
                                </template>
                                <template #content>
                                    <p>
                                        {{ $t('add-new-environment.steps.software.tooltip-content') }}
                                    </p>
                                </template>
                            </Tooltip>
                        </div>
                        <Transition>
                            <div v-if='!loadingSoftware'>
                                <Dropdown
                                    v-model='softwareSelection'
                                    class='mt-6 z-10'
                                    default-value='allstates'
                                    :disabled='hasResellerReferral'
                                >
                                    <DropdownGroup scrollable>
                                        <DropdownItem
                                            v-for='software in softwareList'
                                            :id='software.id'
                                            :name='software.slug === "custom-made" ? $t("add-new-environment.steps.software.list-other") : software.name'
                                            :key='software.id'
                                            :class='{ "font-bold": software.slug === "custom-made" }'
                                        >
                                            <div v-if='software.slug === "custom-made"'>
                                                {{ $t('add-new-environment.steps.software.list-other') }}
                                            </div>
                                            <div v-else>
                                                {{ software.name }}
                                            </div>
                                        </DropdownItem>
                                    </DropdownGroup>
                                </Dropdown>
                                <Tooltip faded class='self-start mt-2' v-if='hasResellerReferral'>
                                    <template #trigger>
                                        <div class='flex items-center gap-1 text-grey-500'>
                                            <Icon name='InformationCircle' family='outline' />
                                            {{ $t('add-new-environment.tooltip-referral-info-trigger') }}
                                        </div>
                                    </template>
                                    <template #content>
                                        {{ $t('add-new-environment.tooltip-referral-info-content') }}
                                    </template>
                                </Tooltip>
                            </div>
                        </Transition>
                        <FormInput
                            v-if='softwareSelection === customMadeSoftwareId'
                            name='customSoftware'
                            :label='$t("add-new-environment.steps.software.label-custom-software")'
                            rules='required|max:160'
                            :edit='true'
                            :placeholder='$t("add-new-environment.steps.software.placeholder-custom-software")'
                            id='customSoftware'
                            class='mt-6'
                            :value='customSoftware'
                            @input='value => customSoftware = value'
                        />
                        <div v-if='softwareSelection && requiresReference()'>
                            <FormInput
                                name='customerReference'
                                ref='customerReference'
                                :label='$t("add-new-environment.steps.software.label-software-customer-ref")'
                                :placeholder='$t("add-new-environment.steps.software.placeholder-software-customer-ref")'
                                rules='max:160'
                                :edit='true'
                                id='customerReference'
                                class='mt-6'
                            />
                            <Tooltip faded class='self-start mt-2'>
                                <template #trigger>
                                    <div class='flex items-center gap-1 text-grey-500'>
                                        <Icon name='InformationCircle' family='outline' />
                                        {{ $t('add-new-environment.steps.software.label-tooltip-customer-ref') }}
                                    </div>
                                </template>
                                <template #content>
                                    {{ $t('add-new-environment.steps.software.tooltip-customer-ref') }}
                                </template>
                            </Tooltip>
                        </div>
                        <div v-if='softwareSelection && isExactOnline() && organization.isDirectCustomer'>
                            <FormInput
                                name='exactMailbox'
                                ref='exactMailbox'
                                :label='$t("add-new-environment.steps.software.label-exact-mailbox")'
                                :placeholder='$t("add-new-environment.steps.software.placeholder-exact-mailbox")'
                                rules='exactEmail|max:160'
                                :edit='true'
                                id='exactMailbox'
                                class='mt-6'
                            />
                            <Tooltip faded class='self-start mt-2'>
                                <template #trigger>
                                    <div class='flex items-center gap-1 text-grey-500'>
                                        <Icon name='InformationCircle' family='outline' />
                                        {{ $t('add-new-environment.steps.software.label-tooltip-exact-online') }}
                                    </div>
                                </template>
                                <template #content>
                                    {{ $t('add-new-environment.steps.software.tooltip-exact-online') }}
                                </template>
                            </Tooltip>
                        </div>
                        <WizardButtons>
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitSoftwareStep(nextStep, values) })'
                                id='env-software-next-step'
                                label='btn-continue'
                                :disabled='!softwareStepValid(values)'
                            />
                        </WizardButtons>
                    </WizardStepContent>
                </Form>
            </WizardStep>
            <WizardStep title='add-new-environment.steps.reseller.title' icon='User'>
                <Form
                    tag='div'
                    v-slot='{ handleSubmit }'
                    class='flex flex-col grow'
                >
                    <WizardStepContent bordered class='h-full flex flex-col grow'>
                        <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                            {{ $t('add-new-environment.steps.reseller.content') }}
                        </CustomTitle>
                        <Tooltip faded class='self-start'>
                            <template #trigger>
                                <div class='flex items-center gap-1 text-grey-500'>
                                    <Icon name='InformationCircle' family='outline' />
                                    {{ $t('add-new-environment.steps.reseller.tooltip-trigger') }}
                                </div>
                            </template>
                            <template #content>
                                {{ $t('add-new-environment.steps.reseller.tooltip-content') }}
                            </template>
                        </Tooltip>
                        <Dropdown
                            v-model='resellerSelection'
                            class='w-full mt-6'
                            default-value='allstates'
                            :disabled='hasResellerReferral'
                        >
                            <DropdownGroup>
                                <DropdownItem
                                    class='w-full'
                                    v-for='option in resellerList'
                                    :id='option.id'
                                    :name='option.slug === "en-direct" ? $t("add-new-environment.steps.reseller.list-other") : option.name'
                                    :key='option.id'
                                    :class='{ "font-bold": resellerList.length > 1 && option.slug === "en-direct" }'
                                >
                                    <div v-if='option.slug === "en-direct"'>
                                        {{ $t('add-new-environment.steps.reseller.list-other') }}
                                    </div>
                                    <div v-else>
                                        {{ option.name }}
                                    </div>
                                </DropdownItem>
                            </DropdownGroup>
                        </Dropdown>
                        <Tooltip faded class='self-start mt-2' v-if='hasResellerReferral'>
                            <template #trigger>
                                <div class='flex items-center gap-1 text-grey-500'>
                                    <Icon name='InformationCircle' family='outline' />
                                    {{ $t('add-new-environment.tooltip-referral-info-trigger') }}
                                </div>
                            </template>
                            <template #content>
                                {{ $t('add-new-environment.tooltip-referral-info-content') }}
                            </template>
                        </Tooltip>

                        <FormInput
                            v-if='resellerSelection === enDirectResellerId'
                            name='customReseller'
                            :label='$t("add-new-environment.steps.reseller.label-custom")'
                            :placeholder='$t("add-new-environment.steps.reseller.placeholder-custom")'
                            rules='required|max:160'
                            :edit='true'
                            id='customReseller'
                            class='mt-6'
                            :value='customReseller'
                            @input='value => customReseller = value'
                        />

                        <WizardButtons>
                            <WizardPreviousButton @click='previousStep' label='add-new-environment.btn-previous' />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { nextStep(); })'
                                id='env-reseller-next-step'
                                label='btn-continue'
                                :disabled='!resellerSelection'
                            />
                        </WizardButtons>
                    </WizardStepContent>
                </Form>
            </WizardStep>
            <!-- Legal representative step, displayed only if the organisation creation date is older that 2 weeks -->
            <WizardStep title='add-new-environment.steps.legal-representative.title' icon='CheckBadge' v-if='organization.created'>
                <Form
                    tag='div'
                    v-slot='{ handleSubmit, values }'
                    class='flex flex-col grow'
                >
                    <WizardStepContent bordered class='h-full flex flex-col grow'>
                        <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                            {{ $t('add-new-environment.steps.legal-representative.content') }}
                        </CustomTitle>
                        <Tooltip faded class='self-start'>
                            <template #trigger>
                                <div class='flex items-center gap-1 text-grey-500'>
                                    <Icon name='InformationCircle' family='outline' />
                                    {{ $t('add-new-environment.steps.legal-representative.tooltip-trigger') }}
                                </div>
                            </template>
                            <template #content>
                                {{ $t('add-new-environment.steps.legal-representative.tooltip-content') }}
                            </template>
                        </Tooltip>
                        <FormInput
                            name='representativeName'
                            :value='organization.representativeName'
                            :label='$t("add-new-environment.steps.legal-representative.lbl-name")'
                            :placeholder='$t("add-new-environment.steps.legal-representative.placeholder-name")'
                            rules='composedName|required|max:100'
                            edit
                            id='representativeName'
                            clean
                            class='my-3'
                        />
                        <FormInput
                            name='representativeEmailAddress'
                            :value='organization.representativeEmailAddress'
                            :label='$t("add-new-environment.steps.legal-representative.lbl-email")'
                            :placeholder='$t("add-new-environment.steps.legal-representative.placeholder-email")'
                            rules='email|required'
                            edit
                            id='representativeEmailAddress'
                            clean
                            class='mb-3'
                        />
                        <FormInput
                            name='representativeFunction'
                            :value='organization.representativeFunction'
                            :label='$t("add-new-environment.steps.legal-representative.lbl-function")'
                            :placeholder='$t("add-new-environment.steps.legal-representative.placeholder-function")'
                            rules='required|max:128'
                            edit
                            class='mb-3'
                            id='representativeFunction'
                        />
                        <FormInput
                            name='representativePhoneNumber'
                            :value='organization.representativePhoneNumber'
                            :label='$t("add-new-environment.steps.legal-representative.lbl-phone-number")'
                            rules='phone|required'
                            edit
                            id='representativePhoneNumber'
                        />
                        <WizardButtons>
                            <WizardPreviousButton @click='previousStep' label='add-new-environment.btn-previous' />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { submitLegalRepresentativeStep(values, nextStep) })'
                                id='env-legal-representative-next-step'
                                label='btn-continue'
                            />
                        </WizardButtons>
                    </WizardStepContent>
                </Form>
            </WizardStep>
            <WizardStep title='add-new-environment.steps.terms-and-conditions.title' icon='ListBullet'>
                <WizardStepContent bordered class='h-full flex flex-col grow'>
                    <CustomTitle :level='2' :style-of='3' variant='light' no-default-margins>
                        {{ $t('add-new-environment.steps.terms-and-conditions.content') }}
                    </CustomTitle>
                    <Form tag='div' v-slot='{ values, handleSubmit }' class='mt-6 flex flex-col grow'>
                        <FormCheckbox
                            name='termsAndConditions'
                            :html-label='$t("add-new-environment.steps.terms-and-conditions.label-checkbox", { termsConditionsUrl })'
                            edit
                        />
                        <WizardButtons>
                            <WizardPreviousButton @click='previousStep' label='add-new-environment.btn-previous' />
                            <WizardNextButton
                                @click='handleSubmit($event, () => { addNewEnvironment(values) })'
                                id='create-environment'
                                :disabled='!values.termsAndConditions'
                                :loading='Loader.getState().loading'
                                label='add-new-environment.btn-add-new-env'
                            />
                        </WizardButtons>
                    </Form>
                </WizardStepContent>
            </WizardStep>
        </Wizard>
    </Popup>
</template>
